import { Injectable, inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { JwtState } from '@wizbii-utils/angular/stores';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class IsAlreadyConnectedGuard {
  readonly #store = inject(Store);
  readonly #router = inject(Router);

  canActivate(): Observable<boolean | UrlTree> {
    return this.#store.select(JwtState).pipe(
      map((tokens) => !!tokens),
      map((isLogged) => {
        if (isLogged) {
          return this.#router.parseUrl('/');
        }

        return true;
      })
    );
  }
}
