<header class="header" role="banner">
  <div class="header__nav-left">
    <a class="header__nav-left__logo" routerLink="/">
      <mat-icon class="header__nav-left__logo__icon" svgIcon="sourcii-logo" />
    </a>
    <a
      class="header__nav-left__item"
      [routerLink]="['/', FeaturesRoutingEnum.Events]"
      routerLinkActive="header__nav-left__item--active"
    >
      Événements
    </a>
    <a
      class="header__nav-left__item"
      [routerLink]="['/', FeaturesRoutingEnum.Candidates]"
      routerLinkActive="header__nav-left__item--active"
    >
      Candidats
    </a>
    <a
      class="header__nav-left__item"
      [routerLink]="['/', FeaturesRoutingEnum.Companies]"
      routerLinkActive="header__nav-left__item--active"
    >
      Entreprises
    </a>
  </div>

  <div class="header__nav-right">
    <div class="header__nav-right__item">
      @if (userProfile$ | async; as userProfile) {
        @if (userProfile?.licenceId) {
          <app-tel-on-off [isOnline]="isOnline$ | async" />
        }

        @if (userProfile?.licenceId) {
          <app-voice-mail />
        }

        @if (userProfile) {
          <app-notifications-pop-up [userId]="userProfile.id" />
        }
      }
      <app-popup (openedChange)="showMenu = !showMenu">
        <button class="header__nav-right__profile btn-clean" type="button" appPopupTrigger>
          @if (userProfile$ | async; as userProfile) {
            <span class="header__nav-right__profile__name">
              {{ userProfile?.firstName | titlecase }} {{ userProfile?.lastName | titlecase }}
            </span>
          }

          <app-logo
            class="header__nav-right__profile__logo"
            [item]="{ firstName: (userProfile$ | async)?.firstName, lastName: (userProfile$ | async)?.lastName }"
            background="#8782ff"
            size="small"
          />

          <mat-icon
            class="header__nav-right__profile__chevron-down"
            [class.header__nav-right__profile__chevron-down--rotate]="showMenu"
            svgIcon="chevron-down"
          />
        </button>

        <div class="profile-dropdown" appPopupItem>
          @if (canAccessAdmin$ | async) {
            <a class="profile-dropdown__link" [routerLink]="['/', FeaturesRoutingEnum.Administration]">
              <mat-icon class="profile-dropdown__link__icon" svgIcon="settings" />
              Administration
            </a>
          }

          <button class="profile-dropdown__link btn-clean" (click)="doSignOut()" type="button">
            <mat-icon class="profile-dropdown__link__icon" svgIcon="user" />
            Se déconnecter
          </button>
        </div>
      </app-popup>
    </div>
  </div>
</header>

<router-outlet />
