@if (modalService.activeModal$ | async; as modal) {
  <wiz-modal
    class="wiz-modal"
    [maxWidth]="modal.props.maxWidth"
    [hideCloseBtn]="modal.props.hideCloseBtn"
    (closed)="modalService.dismissActiveModal()"
    open
  >
    <ng-container *ngTemplateOutlet="modal.template; context: modal.context" />
  </wiz-modal>
}
