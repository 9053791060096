import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, inject } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext, Store, createSelector } from '@ngxs/store';
import { SessionStateEnum } from '@shared/models/enums/session-state.enum';
import { UserRoleEnum, UserRoleLabel, sourcingRoles } from '@shared/models/enums/user-roles-label.enum';
import { User } from '@shared/models/user';
import { extractInfoFromJwt } from '@shared/utils/formatters';
import { getNumberFromLicenceId, prettyPrintNumber } from '@shared/utils/voip/voip-number';
import { GetNotifications } from '@store/notifications/notifications.action';
import { DeleteUserToken, GetUserProfile, SetRedirectPage, SetSseToken, SetState } from '@store/user/user.action';
import { UserService } from '@webservices/user-api/user.service';
import { VoipService } from '@webservices/voip-api/voip.service';
import { JwtDelete, JwtState } from '@wizbii-utils/angular/stores';
import { forkJoin } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

interface UserStateModel {
  sseToken: string | null;
  userProfile: User | null;
  roles: string[];
  loginError: boolean;
  redirectPage: string | null;
  state: SessionStateEnum;
}

const defaultState: UserStateModel = {
  sseToken: null,
  userProfile: null,
  roles: [],
  loginError: false,
  redirectPage: null,
  state: SessionStateEnum.Init,
};

@State<UserStateModel>({
  name: 'user',
  defaults: defaultState,
})
@Injectable()
export class UserState implements NgxsOnInit {
  @Selector()
  static userProfile(state: UserStateModel): User | null {
    return state?.userProfile;
  }

  @Selector()
  static voipLicenceId(state: UserStateModel): string {
    return state?.userProfile?.licenceId;
  }

  @Selector()
  static loginError(state: UserStateModel): boolean {
    return state?.loginError;
  }

  @Selector()
  static redirectPage(state: UserStateModel): string | null {
    return state?.redirectPage;
  }

  @Selector()
  static sseToken(state: UserStateModel): string | null {
    return state?.sseToken;
  }

  @Selector()
  static getUserFullName(state: UserStateModel): string {
    return `${state.userProfile?.firstName} ${state.userProfile?.lastName}`;
  }

  @Selector()
  static getSourcingRoles(state: UserStateModel): (string | undefined)[] {
    return state?.roles
      .filter((role) => sourcingRoles.includes(role))
      .map((role) => UserRoleLabel[role as UserRoleEnum]);
  }

  @Selector()
  static userId(state: UserStateModel): string | undefined {
    return state?.userProfile?.id;
  }

  static hasRole(role: UserRoleEnum): (state: UserStateModel) => boolean {
    return createSelector(
      [UserState],
      (state: UserStateModel) => state.roles.includes(role) || state.roles.includes(UserRoleEnum.ADMIN)
    );
  }

  static hasOneOfRoles(...roles: string[]): any {
    return createSelector(
      [UserState],
      (state: UserStateModel) =>
        state.roles.some((role) => roles.includes(role)) || state.roles.includes(UserRoleEnum.ADMIN)
    );
  }

  readonly #store = inject(Store);
  readonly #voipService = inject(VoipService);
  readonly #userService = inject(UserService);

  constructor(@Inject(DOCUMENT) private readonly document: any) {}

  ngxsOnInit(ctx: StateContext<UserStateModel>): void {
    this.#store
      .select(JwtState)
      .pipe(
        map((jwtState) => jwtState?.token),
        filter((token) => !!token),
        switchMap((jwtToken) => {
          const roles = extractInfoFromJwt(jwtToken, 'roles');
          ctx.patchState({ roles, state: SessionStateEnum.Logged });
          const userId = extractInfoFromJwt(jwtToken, 'user-id');
          const tokenLocal = window.localStorage.getItem('sse_token');
          const token = tokenLocal ? JSON.parse(tokenLocal) : null;
          this.#store.dispatch([new SetSseToken(token)]);
          return this.#store.dispatch([new GetUserProfile(userId), new GetNotifications()]);
        })
      )
      .subscribe();
  }

  @Action(SetSseToken)
  setSseToken(ctx: StateContext<UserStateModel>, { token }: SetSseToken): void {
    if (!token) {
      if (window.localStorage.getItem('sse_token') === null) {
        window.localStorage.removeItem('sse_token');
      }
    } else {
      window.localStorage.setItem('sse_token', JSON.stringify(token));
      ctx.patchState({ sseToken: token });
    }
  }

  @Action(GetUserProfile)
  getUserProfile(ctx: StateContext<UserStateModel>, { profileId }: GetUserProfile): any {
    return forkJoin([this.#userService.getUser(profileId), this.#voipService.listLicences()]).pipe(
      tap(([profile, licences]) => {
        const phoneNumber = getNumberFromLicenceId(licences, profile.licenceId);
        ctx.patchState({
          userProfile: new User({
            ...profile,
            voipNumber: phoneNumber ? prettyPrintNumber(phoneNumber) : null,
          }),
        });
      })
    );
  }

  @Action(SetState)
  setState(ctx: StateContext<UserStateModel>, { state }: SetState): void {
    ctx.patchState({ state });
  }

  @Action(DeleteUserToken)
  deleteUserToken(ctx: StateContext<UserStateModel>): void {
    window.localStorage.removeItem('sse_token');
    this.#store.dispatch(new JwtDelete());
    ctx.patchState({ sseToken: null });
    this.document.location = this.document.location.origin;
  }

  @Action(SetRedirectPage)
  setRedirectPage(ctx: StateContext<UserStateModel>, action: SetRedirectPage): void {
    ctx.patchState({ redirectPage: action.redirectPage, state: SessionStateEnum.NotLogged });
  }
}
