import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MercureCredentialsService {
  readonly #http = inject(HttpClient);

  get(): Observable<{ token: string }> {
    return this.#http.get<{ token: string }>(`${environment.api.opsEvent}/_credentials/mercure`);
  }
}
