import { Company } from '@shared/models/company';
import { OpsEvent } from '@shared/models/ops-event';
import { TrackedLink } from '@shared/models/tracked-link';
import { OpsRecruiter, WizFile } from '@wizbii/utils/models';

export class GetEventDetailSourcePlatform {
  static readonly type = '[OpsEventApi] GetEventDetailSourcePlatform';
}

export class GetEventDetailSearchResult {
  static readonly type = '[SearchApi] GetEventDetailSearchResult';
  constructor(
    public eventId: string,
    public query: string
  ) {}
}

export class GetCompanyDetailInfo {
  static readonly type = '[OpsEventApi] GetComanyDetailInfo';
  constructor(public companyId: string) {}
}

export class UpdateCompanyDetailInfo {
  static readonly type = '[OpsEventApi] UpdateCompanyDetailInfo';
  constructor(
    public company: Company,
    public logoFile: WizFile,
    public altLogoFile: WizFile,
    public smallLogoFile: WizFile
  ) {}
}

export class EmptyCompanyDetail {
  static readonly type = 'EmptyCompanyDetail';
}

export class AddRecruiterToCompany {
  static readonly type = '[OpsEventApi] AddRecruiterToCompany';
  constructor(
    public recruiter: OpsRecruiter,
    public companyId: string
  ) {}
}

export class AddRecruiterToCompanyAndEvent {
  static readonly type = '[OpsEventApi] AddRecruiterToCompanyAndEvent';
  constructor(
    public recruiter: OpsRecruiter,
    public companyId: string,
    public eventId: string
  ) {}
}
export class UpdateCompanyRecruiter {
  static readonly type = '[OpsEventApi] UpdateCompanyRecruiter';
  constructor(
    public recruiter: OpsRecruiter,
    public companyId: string
  ) {}
}

export class EmptyEventDetail {
  static readonly type = 'EmptyEventDetail';
}

export class DownloadEventDetailExtract {
  static readonly type = '[OpsEventApi] DownloadEventDetailExtract';
  constructor(public eventId: string) {}
}

export class DownloadEventDetailQuestionExtract {
  static readonly type = '[OpsEventApi] DownloadEventDetailQuestionExtract';
  constructor(public eventId: string) {}
}

export class DownloadEventImageConsentPdf {
  static readonly type = '[OpsEventApi] Download event image consent PDF';
  constructor(public eventId: string) {}
}

export class DownloadEventConsentCsvExtract {
  static readonly type = '[OpsEventApi] Download event consent csv listing';
  constructor(public eventId: string) {}
}

export class DownloadEventJobList {
  static readonly type = '[OpsEventApi] Download event job list';
  constructor(public eventId: string) {}
}

export class GetTasUsersList {
  static readonly type = '[UserApi] get tas list';
}

export class GenerateDefaultTrackedLinks {
  static readonly type = '[OpsEventApi] generate default tracked links';
  constructor(
    public eventId: string,
    public customDestination: string | null = null
  ) {}
}

export class DeleteTrackedLink {
  static readonly type = '[OpsEventApi] delete tracked link';
  constructor(
    public eventId: string,
    public trackedLinkId: string
  ) {}
}

export class GetEventDetailCompany {
  static readonly type = '[OpsEventApi] get company details';
  constructor(public companyId: string) {}
}

export class CreateTrackedLink {
  static readonly type = '[OpsEventApi] Create Tracked Link';
  constructor(
    public eventId: string,
    public trackedLink: TrackedLink
  ) {}
}

export class GetEventDetailDistinctUtm {
  static readonly type = '[OpsEventApi] GetEventDetailDistinctUtm';
  constructor(public eventId: string) {}
}

export class GetContracts {
  static readonly type = '[JobApi] GetContracts';
}

export class GetDegrees {
  static readonly type = '[SchoolApi] GetDegrees';
}

export class GetEvent {
  static readonly type = '[OpsEventApi] GetEvent';
  constructor(public eventId: string) {}
}

export class DeleteEvent {
  static readonly type = '[OpsEventApi] Delete Event';
  constructor(public eventId: string) {}
}

export class SaveFileBeforeSaveEvent {
  static readonly type = '[OpsEventApi] Save file before Save Event';
  constructor(
    public files?: {
      thumbnail?: WizFile;
      banner?: WizFile;
      smallSocialNetwork?: WizFile;
      bigSocialNetwork?: WizFile;
      invitation?: WizFile;
      badge?: WizFile;
      candidateGuide?: WizFile;
      summary?: WizFile;
      primaryImage?: WizFile;
    }
  ) {}
}

export class InitCreateEventState {
  static readonly type = 'Init Create Event State';
}

export class ShowCreateEventInvitationFile {
  static readonly type = 'ShowCreateEventInvitationFile';
}

export class ShowCreateEventBadgeFile {
  static readonly type = 'ShowCreateEventBadgeFile';
}

export class ShowCreateEventCandidateGuideFile {
  static readonly type = 'ShowCreateEventCandidateGuideFile';
}

export class ShowCreateEventSummaryFile {
  static readonly type = 'ShowCreateEventSummaryFile';
}

export class UpdateOpsEvent {
  static readonly type = '[OpsEventApi] Update OpsEvent';
  constructor(public opsEvent: OpsEvent) {}
}
