import { convertDate } from '@shared/utils/date';

export interface Sms {
  id: string;
  tasUserId?: string;
  candidateId?: string;
  body: string;
  dateCreated: Date;
  dateReceived: Date;
}

export function toSms(data: any = {}): Sms {
  return {
    ...data,
    id: data._id,
    dateCreated: convertDate(data.dateCreated),
    dateReceived: convertDate(data.dateReceived),
  };
}
