import { City } from '@wizbii/utils/models';

export class CandidateHistory {
  id: string;
  name: string;
  dateCreated: Date;
  type: string;
  location: City;

  constructor(data: Record<string, any>) {
    this.id = data['id'];
    this.name = data['name'];
    this.dateCreated = new Date(data['dateCreated']);
    this.type = data['type'];
    this.location = data['location'];
  }
}
