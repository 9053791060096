<!-- eslint-disable @angular-eslint/template/conditional-complexity -->
@if (_date && mode === modeEnum.Long) {
  il y a
  @if (years > 0) {
    {{ years
    }}<ng-container [ngPlural]="years"
      ><ng-template ngPluralCase="zero"><ng-container> an</ng-container></ng-template
      ><ng-template ngPluralCase="one"><ng-container> an</ng-container></ng-template
      ><ng-template ngPluralCase="other"><ng-container> ans</ng-container></ng-template>
    </ng-container>
  }
  @if (years === 0 && months > 0) {
    {{ months }}<ng-container> mois</ng-container>
  }
  @if (years === 0 && months === 0 && days > 0) {
    {{ days
    }}<ng-container [ngPlural]="days"
      ><ng-template ngPluralCase="zero"><ng-container> jour</ng-container></ng-template
      ><ng-template ngPluralCase="one"><ng-container> jour</ng-container></ng-template
      ><ng-template ngPluralCase="other"><ng-container> jours</ng-container></ng-template></ng-container
    >
  }
  @if (years === 0 && months === 0 && days === 0 && hours > 0) {
    {{ hours
    }}<ng-container [ngPlural]="hours"
      ><ng-template ngPluralCase="zero"><ng-container> heure</ng-container></ng-template
      ><ng-template ngPluralCase="one"><ng-container> heure</ng-container></ng-template
      ><ng-template ngPluralCase="other"><ng-container> heures</ng-container></ng-template></ng-container
    >
  }
  @if (years === 0 && months === 0 && days === 0 && hours === 0 && minutes > 0) {
    {{ minutes
    }}<ng-container [ngPlural]="minutes"
      ><ng-template ngPluralCase="zero"><ng-container> minute</ng-container></ng-template
      ><ng-template ngPluralCase="one"><ng-container> minute</ng-container></ng-template
      ><ng-template ngPluralCase="other"><ng-container> minutes</ng-container></ng-template></ng-container
    >
  }
  @if (years === 0 && months === 0 && days === 0 && hours === 0 && minutes === 0) {
    &nbsp;quelques instants
  }
}
@if (_date && mode === modeEnum.Short) {
  @if (years > 0) {
    {{ years
    }}<ng-container [ngPlural]="years"
      ><ng-template ngPluralCase="zero"><ng-container> an</ng-container></ng-template
      ><ng-template ngPluralCase="one"><ng-container> an</ng-container></ng-template
      ><ng-template ngPluralCase="other"
    /></ng-container>
  }
  @if (years === 0 && months > 0) {
    {{ months }}<ng-container> mois</ng-container>
  }
  @if (years === 0 && months === 0 && days > 0) {
    {{ days
    }}<ng-container [ngPlural]="days"
      ><ng-template ngPluralCase="zero"><ng-container> jr</ng-container></ng-template>
      <ng-template ngPluralCase="one"><ng-container> jr</ng-container></ng-template
      ><ng-template ngPluralCase="other"><ng-container> jrs</ng-container></ng-template></ng-container
    >
  }
  @if (years === 0 && months === 0 && days === 0 && hours > 0) {
    {{ hours }}<ng-container> h</ng-container>
  }
  @if (years === 0 && months === 0 && days === 0 && hours === 0 && minutes > 0) {
    {{ minutes }}<ng-container> min</ng-container>
  }
}
