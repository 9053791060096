<router-outlet />

<app-toaster />

@if (voipLicenceId$ | async; as licenceId) {
  <app-voip-toaster [voipLicenceId]="licenceId" />
}

<app-global-modal />

@if (!!(jwtState$ | async)) {
  <wiz-sidebar class="wiz-sidebar" [environment]="platform" />
}
