import { Injectable, TemplateRef, inject, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScrollLockService } from '@core/services/scroll-lock.service';
import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

interface WizModalProps {
  maxWidth?: number | 'none';
  hideCloseBtn?: boolean;
}

interface ModalConfig {
  template: TemplateRef<any>;
  queryParam: string;
  destroyed$: Observable<void>;
  activateConfirm?: boolean;
  props?: WizModalProps;
  closeConfirmModal$?: Observable<boolean>;
}

export interface ModalRef {
  template: TemplateRef<any>;
  context: { $implicit: string };
  props: WizModalProps;
}
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  readonly #router = inject(Router);
  readonly #route = inject(ActivatedRoute);
  readonly #scrollLockService = inject(ScrollLockService);

  readonly #activeQueryParam = signal<string | undefined>(undefined);
  readonly #modalSubject = new ReplaySubject<any>(1);
  readonly #activateConfirm = signal(false);
  #closeConfirmModal$: Observable<boolean>;
  readonly #showConfirm = signal(false);

  get activeModal$(): Observable<any> {
    return this.#modalSubject.asObservable();
  }

  register(modalConfig: ModalConfig): void {
    const { queryParam, destroyed$ } = modalConfig;
    this.#activateConfirm.set(modalConfig.activateConfirm || false);
    this.#closeConfirmModal$ = modalConfig.closeConfirmModal$ || null;

    this.#route.queryParamMap.pipe(takeUntil(destroyed$)).subscribe({
      next: (queryParamMap) => {
        if (queryParamMap.has(queryParam)) {
          this.#open(modalConfig, queryParamMap.get(queryParam));
        } else if (this.#activeQueryParam() === queryParam) {
          this.#close();
        }
      },
    });

    if (this.#closeConfirmModal$) {
      this.#closeConfirmModal$.pipe(takeUntil(destroyed$), distinctUntilChanged()).subscribe((bool) => {
        this.#showConfirm.set(bool);
      });
    }
  }

  dismissActiveModal(): void {
    if ((this.#activateConfirm() && !this.#showConfirm()) || !this.#activateConfirm()) {
      this.#router.navigate([], {
        queryParams: { [this.#activeQueryParam()]: undefined, tab: null },
        queryParamsHandling: 'merge',
      });
    }

    if (this.#activateConfirm() && this.#showConfirm() && confirm('Êtes-vous sûr de vouloir fermer ?')) {
      this.#router.navigate([], {
        queryParams: { [this.#activeQueryParam()]: undefined },
        queryParamsHandling: 'merge',
      });
    }
  }
  #open({ template, queryParam, props = {} }: ModalConfig, queryParamValue: string) {
    this.#activeQueryParam.set(queryParam);
    this.#modalSubject.next({ template, context: { $implicit: queryParamValue }, props });
    this.#scrollLockService.lock();
  }

  #close() {
    this.#activeQueryParam.set(undefined);
    this.#modalSubject.next(void 0);
    this.#scrollLockService.unlock();
  }
}
