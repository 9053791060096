<div class="label-button-container">
  @if (showTitle) {
    <label
      class="switch-label"
      [id]="'switch-label-' + id"
      [attr.for]="'switch-button-' + id"
      [tabindex]="disabled ? '-1' : '0'"
      [class.visually-hidden]="hideLabel"
      [class.switch-label--success]="themeSuccess"
    >
      <ng-content select="[slot='title']" />
    </label>
  }
  <input
    class="switch-button__native-input"
    [id]="'native-input-switch-' + id"
    [checked]="checked"
    [disabled]="disabled"
    #input
    type="checkbox"
    role="switch"
    tabindex="-1"
  />
  <button
    class="switch-button"
    [id]="'switch-button-' + id"
    [class.switch-button--marginless]="!showTitle"
    [class.switch-button--success]="themeSuccess"
    [disabled]="disabled"
    [attr.aria-checked]="checked"
    [attr.aria-labelledby]="'switch-label-' + id"
    (click)="clickEvent($event)"
    type="button"
    role="switch"
  >
    &nbsp;
  </button>
</div>

<div class="switch-description">
  <ng-content />
</div>
