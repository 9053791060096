import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { Company, CompanyResponse, companyResponseToCompany } from '@shared/models/company';
import { ContactPerson } from '@shared/models/company-participation';
import { CompanyParticipationDeleteSummary } from '@shared/models/company-participation-delete-summary';
import { OpsEventApiStatusResponseInterface } from '@shared/models/notifications/interfaces/ops-event-api-status-response.interface';
import { OpsEventStatusResponseInterface } from '@shared/models/notifications/interfaces/ops-event-status-response.interface';
import { RecruiterResponseToSearchRecruiter, SearchRecruiter } from '@shared/models/search-recruiter';
import { OpsRecruiter } from '@wizbii/utils/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CompanyService {
  readonly baseUrl = `${environment.api.opsEvent}/api/company`;
  readonly baseUrlV2 = `${environment.api.opsEvent}/v2/company`;

  readonly #http = inject(HttpClient);

  createCompany(company: Partial<Company>): Observable<Company> {
    return this.#http.post(`${this.baseUrlV2}`, company).pipe(map((companyResponse) => new Company(companyResponse)));
  }

  getCompany(companyId: string): Observable<Company> {
    return this.#http
      .get<CompanyResponse>(`${this.baseUrlV2}/${companyId}`)
      .pipe(map((companyResponse) => new Company(companyResponse)));
  }

  updateCompany(company: Company): Observable<Company> {
    return this.#http
      .put(`${this.baseUrlV2}/${company.id}`, company)
      .pipe(map((companyResponse) => new Company(companyResponse)));
  }

  getCompanyParticipations(eventId: string): Observable<Company[]> {
    return this.#http
      .get<CompanyResponse[]>(`${this.baseUrlV2}/by-event/${eventId}`)
      .pipe(
        map((companiesResponse) =>
          companiesResponse.map((companyResponse) => companyResponseToCompany(companyResponse))
        )
      );
  }

  addCompanyToEvent(eventId: string, companyId: string): Observable<OpsEventStatusResponseInterface> {
    return this.#http.post<OpsEventStatusResponseInterface>(
      `${this.baseUrlV2}/${companyId}/participation/${eventId}`,
      null
    );
  }

  deleteCompanyParticipation(companyId: string, eventId: string): Observable<OpsEventApiStatusResponseInterface> {
    return this.#http.delete<OpsEventApiStatusResponseInterface>(
      `${this.baseUrlV2}/${companyId}/participation/${eventId}`
    );
  }

  getCompanyRecruiter(companyId: string): Observable<OpsRecruiter[]> {
    return this.#http.get<OpsRecruiter[]>(`${this.baseUrlV2}/${companyId}/recruiters`);
  }

  addEventRecruiter(companyId: string, recruiterId: string, eventId: string): Observable<CompanyResponse> {
    return this.#http.post<CompanyResponse>(
      `${this.baseUrlV2}/${companyId}/recruiter/${recruiterId}/participation/${eventId}`,
      null
    );
  }

  updateCompanyRecruiter(recruiterParams: OpsRecruiter, companyId: string): Observable<SearchRecruiter> {
    return this.#http
      .put(`${this.baseUrlV2}/${companyId}/recruiter/${recruiterParams.id}`, {
        firstName: recruiterParams.firstName,
        lastName: recruiterParams.lastName,
        phone: recruiterParams.phone,
        mail: recruiterParams.mail,
        position: recruiterParams.position,
        status: recruiterParams.status,
      })
      .pipe(map((recruiterResponse: any) => RecruiterResponseToSearchRecruiter(recruiterResponse, companyId)));
  }

  deleteEventRecruiter(companyId: string, recruiterId: string, eventId: string): Observable<CompanyResponse> {
    return this.#http.delete<CompanyResponse>(
      `${this.baseUrlV2}/${companyId}/recruiter/${recruiterId}/participation/${eventId}`
    );
  }

  updateCommittees(
    committees: { id?: string; recruitersIds: string[]; jobsIds: string[] }[],
    companyId: string,
    eventId: string
  ): Observable<void> {
    return this.#http.put<void>(`${this.baseUrlV2}/${companyId}/participation/${eventId}/committees`, { committees });
  }

  updateContactPerson(contactPerson: ContactPerson, companyId: string, eventId: string): Observable<void> {
    return this.#http.put<void>(`${this.baseUrlV2}/${companyId}/participation/${eventId}/contact-person`, {
      ...contactPerson,
    });
  }

  getCompanyDeleteParticipationSummary(
    companyId: string,
    eventId: string
  ): Observable<CompanyParticipationDeleteSummary> {
    return this.#http.get<CompanyParticipationDeleteSummary>(
      `${this.baseUrlV2}/${companyId}/participation/${eventId}/delete`
    );
  }
}
