import { Licence } from '@shared/models/licence';

export function prettyPrintNumber(number: string): string {
  return (
    number
      ?.replace('+33', '0')
      ?.match(/.{1,2}/g)
      ?.join(' ') || ''
  );
}

export function getNumberFromLicenceId(licences: Licence[], licenceId: string): string | null {
  return licences.find((licence) => licence.id === licenceId)?.phoneNumber;
}
