import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { CandidateParticipation } from '@shared/models/candidate-participation';
import { Licence } from '@shared/models/licence';
import { User } from '@shared/models/user';
import { Call } from '@shared/models/voip/call';
import { CallStatusEnum } from '@shared/models/voip/call-status.enum';
import { Line } from '@shared/models/voip/line';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class VoipService {
  private readonly baseUrl = `${environment.api.voip}/api`;

  readonly #http = inject(HttpClient);

  listLicences(): Observable<Licence[]> {
    return this.#http
      .get<Record<string, unknown>[]>(`${this.baseUrl}/admin/licences`)
      .pipe(map((list) => list.map((it) => new Licence(it))));
  }

  updateUserLicence(user: User): Observable<any> {
    if (!user.licenceId) {
      return this.#http.delete(`${this.baseUrl}/profile/ops/${user.id}/clear`, {});
    }
    return this.#http.post(`${this.baseUrl}/profile/ops/${user.id}/assign/${user.licenceId}`, {});
  }

  call(
    voipLicenceId: string,
    candidateNumber: string,
    candidateInfo: CandidateParticipation,
    eventId: string
  ): Observable<Call> {
    const candidateName = `${candidateInfo?.firstName} ${candidateInfo?.lastName}`;
    return this.#http
      .post<any>(`${this.baseUrl}/call`, {
        licenceId: voipLicenceId,
        number: candidateNumber,
        label: candidateName,
        sourciiCandidateParticipation: candidateInfo,
      })
      .pipe(
        map((callHandle) => {
          return {
            callHandle: callHandle?.callHandle,
            callerName: candidateName,
            callStatus: CallStatusEnum.Out,
            callStart: undefined,
            eventId: eventId,
            candidateId: candidateInfo.id,
            voipLicenceId: voipLicenceId,
          };
        })
      );
  }

  pickUp(voipLicenceId: string, callHandle: string): Observable<any> {
    return this.#http.post<any>(`${this.baseUrl}/call/pickup`, {
      licenceId: voipLicenceId,
      callHandle: callHandle,
    });
  }

  hangUp(voipLicenceId: string, callHandle: string): Observable<any> {
    return this.#http
      .post<any>(`${this.baseUrl}/call/hangup`, {
        licenceId: voipLicenceId,
        callHandle: callHandle,
      })
      .pipe(map(() => callHandle));
  }

  getLineStatus(voipLicenceId: string): Observable<Line> {
    return this.#http.get<Line>(`${this.baseUrl}/line/${voipLicenceId}/status`);
  }

  getVoiceMailMsg(voipLicenceId: string): Observable<{ nbMessage: number }> {
    return this.#http.get<{ nbMessage: number }>(`${this.baseUrl}/voicemail/messages/${voipLicenceId}`);
  }
}
