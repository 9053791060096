import { CompanyParticipation } from '@shared/models/company-participation';
import { ArchivedPublishedStatusEnum } from '@shared/models/enums/archived-published-status.enum';
import { InvitedConfirmedStatusEnum } from '@shared/models/invited-confirmed-status';
import { OpsJob } from '@shared/models/ops-job';
import { City, OpsRecruiter, UnavailabilitySlot } from '@wizbii/utils/models';

export class Company {
  id: string;
  name: string;
  domains: string[];
  location?: City;
  logoFileId?: string;
  altLogoFileId?: string;
  smallLogoFileId?: string;
  description?: string;
  isClient?: boolean;
  status?: ArchivedPublishedStatusEnum;
  participations: CompanyParticipation[];
  orias?: string;
  rcs?: string;
  address?: string;
  instaHandle?: string;
  initiativeTitle?: string;
  initiativeDescription?: string;

  constructor(data: Record<string, any> = {}) {
    this.id = data['id'];
    this.name = data['name'];
    this.domains = data['domains'];
    this.location = data['location'];
    this.logoFileId = data['logoFileId'];
    this.altLogoFileId = data['altLogoFileId'];
    this.smallLogoFileId = data['smallLogoFileId'];
    this.description = data['description'];
    this.isClient = data['isClient'];
    this.status = data['status'];
    this.participations = data['participations'];
    this.orias = data['orias'];
    this.rcs = data['rcs'];
    this.address = data['address'];
    this.instaHandle = data['instaHandle'];
    this.initiativeTitle = data['initiativeTitle'];
    this.initiativeDescription = data['initiativeDescription'];
  }
}

export class CompanyMinimalData {
  id: string;
  name: string;
  location?: City;
  isClient?: boolean;
  status?: ArchivedPublishedStatusEnum;
}

interface CommitteesResponse {
  id: string;
  jobsIds: string[];
  recruitersIds: string[];
  endAttendance?: string;
  unavailabilitySlots?: UnavailabilitySlot[];
}
interface CompanyParticipationResponse {
  committees: CommitteesResponse[];
  recruiters: OpsRecruiter[];
  jobs: OpsJob[];
  token: string;
  eventId: string;
  cvBookShortUrl: string;
  status: InvitedConfirmedStatusEnum;
}

export interface CompanyResponse {
  id: string;
  name: string;
  domains: string[];
  location?: City;
  logoFileId?: string;
  altLogoFileId?: string;
  smallLogoFileId?: string;
  description?: string;
  isClient?: boolean;
  status?: ArchivedPublishedStatusEnum;
  participations: CompanyParticipationResponse[];
}

export function companyResponseToCompany(company: CompanyResponse): Company {
  return {
    ...company,
    location: company.location ? new City(company.location) : company.location,
    participations: company.participations.map((participation) => ({
      ...participation,
      committees: participation.committees.map((committee) => ({
        ...committee,
        recruiters: committee.recruitersIds.map((recruiterId) =>
          participation.recruiters.find((recruiter) => recruiter.id === recruiterId)
        ),
        jobs: committee.jobsIds.map((jobId) => participation.jobs.find((job) => job.id === jobId)),
      })),
    })),
  };
}
