import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { CoreModule } from '@core/core.module';
import { environment } from '@environment';
import { defineCustomElements as defineModal } from '@wizbii/modal/dist/loader';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

if (environment.isProdBuild) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(CoreModule)
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));

defineModal(window).catch((err) => {
  // eslint-disable-next-line no-console
  console.warn('Unable to register web component <wiz-modal>', err);
});
