export enum ToastType {
  DANGER = 'danger',
  SUCCESS = 'success',
  INFO = 'info',
  NEUTRAL = 'neutral',
}

export type ToastConfig = {
  type: ToastType;
  title?: string;
  message?: string;
  duration?: number;
};

export class FeedbackToast {
  duration: number;
  message: string;
  title: string;
  type: ToastType;
  timer: any;

  constructor(config: ToastConfig) {
    this.duration = config.duration;
    this.message = config.message;
    this.title = config.title;
    this.type = config.type;
  }
}
