import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NotificationsPopUpComponent } from '@core/components/header/notifications-pop-up/notifications-pop-up.component';
import { TelOnOffComponent } from '@core/components/header/tel-on-off/tel-on-off.component';
import { VoiceMailComponent } from '@core/components/header/voice-mail/voice-mail.component';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Select, Store } from '@ngxs/store';
import { UserRoleEnum } from '@shared/models/enums/user-roles-label.enum';
import { User } from '@shared/models/user';
import { DeleteUserToken } from '@store/user/user.action';
import { UserState } from '@store/user/user.state';
import { VoipState } from '@store/voip/voip.state';
import { LogoComponent } from '@widgets/logo/logo.component';
import { PopupModule } from '@widgets/popup/popup.module';
import { Observable } from 'rxjs';

@Component({
  standalone: true,
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    LogoComponent,
    VoiceMailComponent,
    TelOnOffComponent,
    NotificationsPopUpComponent,
    PopupModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
  @Select(UserState.userProfile)
  userProfile$: Observable<User>;

  @Select(UserState.hasOneOfRoles(UserRoleEnum.PROJECT_MANAGER, UserRoleEnum.SOURCING_MANAGER))
  canAccessAdmin$: Observable<boolean>;

  @Select(VoipState.isOnline) isOnline$?: Observable<boolean>;

  showMenu = false;

  readonly FeaturesRoutingEnum = FeaturesRoutingEnum;
  readonly #store = inject(Store);

  doSignOut(): void {
    this.#store.dispatch(new DeleteUserToken());
  }
}
