import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Renderer2,
  ViewChild,
  inject,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Select } from '@ngxs/store';
import { VoipState } from '@store/voip/voip.state';
import { FeedbackToast, ToastType } from '@widgets/toaster/toaster';
import { ToasterService } from '@widgets/toaster/toaster.service';
import { Observable } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  imports: [CommonModule, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToasterComponent {
  @Select(VoipState.isCallListEmpty) isCallListEmpty$: Observable<boolean>;

  toast: FeedbackToast;

  @ViewChild('toaster') toaster: ElementRef;

  readonly ToastType = ToastType;

  timeout: number;
  shift$: Observable<boolean>;

  readonly #toasterService = inject(ToasterService);
  readonly #cdr = inject(ChangeDetectorRef);
  readonly #renderer = inject(Renderer2);

  constructor() {
    this.#toasterService.toasts$?.subscribe((next) => {
      this.toast = next;
      this.timeout = window.setTimeout(() => this.hide(next), 5000);
      this.#cdr.detectChanges();
    });
  }

  hide(toast: FeedbackToast): void {
    if (this.toaster) {
      clearTimeout(toast.timer);
      this.#renderer.addClass(this.toaster.nativeElement, 'fadeOut');

      setTimeout(() => {
        this.toast = null;
        this.#cdr.detectChanges();
      }, 500);
    }
  }
}
