@if (notification) {
  <div class="notification">
    <div class="notification__header">
      <ng-content select="[mainIcon]" />

      <span class="notification__title">
        <ng-content select="[text]" />
      </span>

      <span class="notification__time">
        <app-time-ago [date]="notification.dateCreated" />
      </span>
    </div>

    <div class="notification__info">
      <span class="notification__event">
        {{ notification.event.name }}
      </span>

      @if (notification.subject.owner) {
        <span class="notification__owner">
          {{ notification.subject.owner.firstName | titlecase }} {{ notification.subject.owner.lastName | titlecase }}
        </span>
      }
    </div>

    <span class="treat">
      <ng-content select="[treatIcon]" />
    </span>
  </div>
}
