import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ModalService } from '@core/services/modal.service';

/**
 * Proxy for `<wiz-modal>` web component in order to limit the scope of `CUSTOM_ELEMENTS_SCHEMA`.
 */
@Component({
  standalone: true,
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'app-global-modal',
  templateUrl: './global-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalModalComponent {
  readonly modalService = inject(ModalService);
}
