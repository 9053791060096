import { Call } from '@shared/models/voip/call';
import {
  CallStatusEnum,
  CallStatusScoringEnum,
  LineStatusToCallStatusMappingEnum,
} from '@shared/models/voip/call-status.enum';
import { Line } from '@shared/models/voip/line';

export class Calls {
  static find(callHandle: string, callList: Call[]): Call {
    return callList.find((call) => call.callHandle === callHandle);
  }
  static exists(callHandle: string, callList: Call[]): boolean {
    return callList.some((call) => call.callHandle === callHandle);
  }

  static buildAnonymous(line: Line): Call {
    return {
      callHandle: line.currentCallHandle,
      callStatus: LineStatusToCallStatusMappingEnum[line.state],
      callerName: null,
      callStart: null,
      eventId: null,
      candidateId: null,
    };
  }

  static sortByStatus(calls: Call[]): Call[] {
    if (!calls?.length) return [];
    return calls.sort((a, b) => CallStatusScoringEnum[a.callStatus] - CallStatusScoringEnum[b.callStatus]);
  }

  static hasEnded(call: Call): boolean {
    return call?.callStatus === CallStatusEnum.Ended;
  }

  static isCurrent(call: Call): boolean {
    return call?.callStatus === CallStatusEnum.Current;
  }
}
