import { ArchivedPublishedStatusEnum } from '@shared/models/enums/archived-published-status.enum';

export class SearchRecruiter {
  id: string;
  firstName: string;
  lastName: string;
  mail: string;
  phone: string;
  companyId: string;
  position: string;
  status: ArchivedPublishedStatusEnum;

  constructor(data: Record<string, any>) {
    this.id = data['objectID'];
    this.firstName = data['first_name'];
    this.lastName = data['last_name'];
    this.mail = data['mail'];
    this.phone = data['phone'];
    this.companyId = data['companyId'];
    this.position = data['position'];
    this.status = data['status'];
  }
}

export function RecruiterResponseToSearchRecruiter(data: Record<string, any>, companyId: string): SearchRecruiter {
  return {
    id: data['id'],
    firstName: data['firstName'],
    lastName: data['lastName'],
    mail: data['mail'],
    phone: data['phone'],
    position: data['position'],
    status: data['status'] as ArchivedPublishedStatusEnum,
    companyId: companyId,
  };
}
