export class Licence {
  id: string;
  userId: string;
  phoneNumber: string;
  name: string;

  constructor(data: Record<string, any>) {
    this.id = data['id'];
    this.userId = data['userId'];
    this.phoneNumber = data['phoneNumber'];
    this.name = data['name'];
  }
}
