import { Call } from '@shared/models/voip/call';
import { CallStatusEnum } from '@shared/models/voip/call-status.enum';
import { LineStatusEnum } from '@shared/models/voip/line-status.enum';

export class AddNewCall {
  static readonly type = 'AddNewCall';
  constructor(public newCall: Call) {}
}

export class ClearCall {
  static readonly type = 'ClearCall';
  constructor(public callHandle: string) {}
}

export class UpdateCall {
  static readonly type = 'UpdateCall';
  constructor(
    public callHandle: string,
    public callStatus: CallStatusEnum,
    public callStart: Date
  ) {}
}

export class InitializeLineStatus {
  static readonly type = 'InitializeLineStatus';
  constructor(public voipLicenceId: string) {}
}

export class UpdateLineStateIfFree {
  static readonly type = 'UpdateLineStateIfFree';
  constructor(public state: LineStatusEnum) {}
}

export class UpdateLineStateToFirstCallStatus {
  static readonly type = 'UpdateLineStateToFirstCallStatus';
}

export class UpdateUserStatus {
  static readonly type = 'UpdateUserStatus';
  constructor(public online: boolean) {}
}

export class CheckCallIsStillActive {
  static readonly type = 'CheckCallIsStillActive';
  constructor(public callHandle: string) {}
}
