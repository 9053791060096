<div class="error__header">
  <a class="error__header__back" routerLink="/"><mat-icon class="error__header__back__icon" svgIcon="arrow-left" /></a>
  <div class="error__header__title">Erreur 404</div>
</div>

<div class="error__content">
  <mat-icon class="error__content__icon" svgIcon="not-found" />

  <div class="error__content__title">Page introuvable ou inexistante</div>

  <div class="error__content__text">
    La page ou l'événement que vous recherchez est introuvable. Nous pouvons vous aider à retrouver cette page ou cet
    événement, ou vous ramener à l'accueil.
  </div>

  <a class="btn brand-btn" routerLink="/">Retour au dashboard</a>
</div>
