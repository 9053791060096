<app-switch
  class="switch"
  id="isOnline"
  [ngModel]="isOnline"
  [themeSuccess]="true"
  (ngModelChange)="updateUserStatus($event)"
  name="isOnline"
>
  <ng-container slot="title">
    <mat-icon class="switch__icon" svgIcon="tel-operator" />{{ isOnline ? 'Online' : 'Offline' }}</ng-container
  >
</app-switch>
