@if (call$ | async; as call) {
  @if (call.callStatus; as callStatus) {
    <div class="fadeInUp toaster" #toaster>
      @if (callStatus !== CallStatusEnum.Ended) {
        <div class="toaster__status toaster__status--square toaster__status--primary">
          <mat-icon class="toaster__status__phone-icon" svgIcon="phone-volume" aria-hidden="true" focusable="false" />
        </div>
      }
      @if (callStatus === CallStatusEnum.Ended) {
        <div class="toaster__status toaster__status--square toaster__status--warning">
          <mat-icon class="toaster__status__phone-icon" svgIcon="phone-slash" aria-hidden="true" focusable="false" />
        </div>
      }
      <div class="toaster__text">
        <p class="toaster__text__title">
          Appel
          {{ CallStatusLabelEnum[callStatus] }}
          @if (callStatus === CallStatusEnum.Current) {
            <span class="toaster__text__title__timer">{{ timer$ | async }}</span>
          }
        </p>
        @if (call?.callerName && call?.candidateId) {
          <p class="toaster__text__candidate">
            {{ call.callerName }}

            <a
              class="toast__text__link"
              [routerLink]="[
                '/',
                FeaturesRoutingEnum.Events,
                call.eventId,
                EventDetailRoutingEnum.candidate,
                call.candidateId,
              ]"
              [queryParams]="{ tab: CandidateDetailTabsEnum.informations }"
              >Afficher le profil</a
            >
          </p>
        }
      </div>
      @if (callStatus === CallStatusEnum.Current || callStatus === CallStatusEnum.Out) {
        <div class="toaster__hangup">
          <button class="btn-clean toaster__hangup__btn" (click)="hangUp()" type="button">Raccrocher</button>
        </div>
      }
      @if (callStatus === CallStatusEnum.In) {
        <button
          class="btn-clean toaster__status toaster__status--round toaster__status--success"
          (click)="pickUp()"
          type="button"
        >
          <mat-icon class="toaster__status__phone-icon" svgIcon="phone" />
        </button>

        <button
          class="btn-clean toaster__status toaster__status--round toaster__status--warning"
          (click)="hangUp()"
          type="button"
        >
          <mat-icon class="toaster__status__phone-icon" svgIcon="phone-slash" />
        </button>
      }
      @if (callStatus === CallStatusEnum.Ended) {
        <button class="btn-clean" (click)="closeNotification()" type="button">
          <mat-icon class="toaster__close" svgIcon="cross" />
        </button>
      }
    </div>
  }
}
