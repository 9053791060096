import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { Credentials } from '@wizbii-utils/angular/algolia';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AlgoliaCredentialsService {
  readonly #baseUrl = `${environment.api.opsEvent}/_credentials/algolia`;

  readonly #http = inject(HttpClient);

  getCredentials(): Observable<Credentials> {
    return this.#http.get<Credentials>(this.#baseUrl);
  }
}
