/* eslint-disable no-useless-escape */
import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule, NgZone } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ngxsConfig } from '@config/ngxs.config';
import { routerConfig } from '@config/router.config';
import { CoreComponent } from '@core/components/core.component';
import { GlobalModalComponent } from '@core/components/global-modal.component';
import { QuicklinkSharedModule } from '@core/ngx-quicklink.shared.module';
import { ScrollLockService } from '@core/services/scroll-lock.service';
import { WizbiiRouterStateSerializer } from '@core/wizbii-router-state.serializer';
import { environment } from '@environment';
import { FeaturesModule } from '@features/features.module';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { JwtService } from '@shared/services/jwt.service';
import { SseInterceptorService } from '@shared/services/sse-interceptor.service';
import { SSE_SERVICE_TOKEN } from '@shared/services/sse-interceptor/sse-injection.config';
import { SseInterceptor } from '@shared/services/sse-interceptor/sse.interceptor';
import { NotificationsState } from '@store/notifications/notifications.state';
import { UserState } from '@store/user/user.state';
import { VoipState } from '@store/voip/voip.state';
import { AlgoliaCredentialsService } from '@webservices/ops-event-api/algolia-credentials.service';
import { ToasterComponent } from '@widgets/toaster/toaster.component';
import { VoipComponent } from '@widgets/voip/voip.component';
import {
  ALGOLIA_CREDENTIALS_FETCHERS_TOKEN,
  Credentials,
  CredentialsFetcher,
  CredentialsFetchers,
} from '@wizbii-utils/angular/algolia';
import { JWT_SERVICE_TOKEN, JwtInterceptor } from '@wizbii-utils/angular/jwt';
import { JWT_STATE_CONFIG, JwtState, JwtStateModule } from '@wizbii-utils/angular/stores';
import { MUST_BE_AUTHORIZED_GUARD_CONFIG, PageUnauthorizedModule } from '@wizbii/angular-backoffice-ui';
import { BugsnagGroupingRule, bugsnagErrorHandlerFactory } from '@wizbii/angular-bugsnag';
import { ImageService } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';

const MESSAGE_GROUPS: BugsnagGroupingRule[] = [
  { regex: /(Loading chunk) [0-9]+ (failed)[^]*/, msg: '$1 $2' },
  { regex: /(Uncaught \(in promise\): e: {)[^]*(\"status\":[0-9]{1,3},\"statusText\":\"[^"]+\")[^]*$/, msg: '$1$2}' },
  { regex: /(\/profiles)\/[^/]+(.*):/, msg: '$1/<profile-id>$2:' },
  { regex: /(\/user)\/[^/]+(.*):/, msg: '$1/<user-id>$2:' },
  { regex: /(\/company)\/[^/]+(.*):/, msg: '$1/<company-id>$2:' },
  { regex: /(\/jobs)\/[^/]+(.*):/, msg: '$1/<job-id>$2:' },
  { regex: /(\/schools)\/[^/]+(.*):/, msg: '$1/<school-id>$2:' },
  { regex: /(\/location\?name=)[^:]+/, msg: '$1<keyword>' },
  { regex: /(\/location)\/[^:]+/, msg: '$1/<location-id>' },
  { regex: /(\/wizbii-files)\/[^:]+/, msg: '$1/<file-name>' },
  { regex: /(\/m-api.wizbii.com\/v1)\/[^:]+/, msg: '$1/<media-endpoint>' },
  { regex: /(animation trigger ".+" has failed to build)[^]*/, msg: '$1' },
  { regex: /(JSONP request)[^]*(failed|timed out)$/, msg: '$1 $2' },
];

registerLocaleData(localeFr, 'fr-FR');

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    PageUnauthorizedModule,
    FeaturesModule,
    MatIconModule,
    ToasterComponent,
    VoipComponent,
    GlobalModalComponent,
    ScrollToModule.forRoot(),
    RouterModule.forRoot([], routerConfig),
    QuicklinkSharedModule,
    NgxsModule.forRoot([UserState, NotificationsState, VoipState], ngxsConfig),
    JwtStateModule,
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({ name: 'Sourcii', maxAge: 30, disabled: environment.isProdBuild }),
  ],
  providers: [
    {
      provide: JWT_STATE_CONFIG,
      useValue: {
        jwtCookieName: 'wizbii_bo',
        expiryCookieName: 'wizbii_bo_expiry',
        appEnvFqdn: environment.domain.cookie,
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: JWT_SERVICE_TOKEN,
      useClass: JwtService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SseInterceptor,
      multi: true,
    },
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        panelClass: 'wiz-dialog',
        backdropClass: 'wiz-backdrop',
        hasBackdrop: true,
      },
    },
    {
      provide: SSE_SERVICE_TOKEN,
      useClass: SseInterceptorService,
    },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    {
      provide: MUST_BE_AUTHORIZED_GUARD_CONFIG,
      useFactory: (store: Store) => ({
        listRoles$: store.select(JwtState.roles),
        listAuthorizedRoles: ['ROLE_SOURCING', 'ROLE_OPS_SOURCING_MANAGER', 'ROLE_OPS_PROJECT_MANAGER', 'ROLE_OPS_CRE'],
        platform: environment.platform,
      }),
      deps: [Store],
    },
    {
      provide: ErrorHandler,
      useFactory: bugsnagErrorHandlerFactory({
        apiKey: environment.bugsnagId,
        appVersion: environment.version,
        autoTrackSessions: false,
        releaseStage: environment.platform,
        isDeployed: environment.isDeployed,
        groupingRules: MESSAGE_GROUPS,
      }),
    },
    ScrollLockService,
    {
      provide: ImageService,
      useValue: new ImageService(environment.api.imaginary),
    },
    {
      provide: ALGOLIA_CREDENTIALS_FETCHERS_TOKEN,
      useFactory: (algoliaCredentialsService: AlgoliaCredentialsService): CredentialsFetchers => {
        const credentialsObservable = algoliaCredentialsService.getCredentials();

        return ['events', 'candidates', 'companies', 'recruiters', 'recruitersPosition'].reduce(
          (carry: { string: CredentialsFetcher }, key: string) => ({
            ...carry,
            [key]: {
              fetchCredentials(): Observable<Credentials> {
                return credentialsObservable;
              },
            },
          }),
          {} as any
        );
      },
      deps: [AlgoliaCredentialsService],
    },
    { provide: RouterStateSerializer, useClass: WizbiiRouterStateSerializer },
  ],
  declarations: [CoreComponent],
  bootstrap: [CoreComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {
  // eslint-disable-next-line  max-params
  constructor(ngZone: NgZone, matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    ngZone.runOutsideAngular(() => {
      matIconRegistry.addSvgIconSet(
        domSanitizer.bypassSecurityTrustResourceUrl(`${environment.deployAssetsUrl}/sprite.svg`)
      );
    });
  }
}
