import { StatusParticipationEnum } from '@shared/models/enums/status-participation.enum';

export class CandidateRecentActivity {
  firstName: string;
  lastName: string;
  to: StatusParticipationEnum;
  dateCreated: Date;

  constructor(data: Record<string, any>) {
    this.firstName = data['firstName'];
    this.lastName = data['lastName'];
    this.to = data['to'];
    this.dateCreated = new Date(data['dateCreated']);
  }
}
