import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { TrackedLink } from '@shared/models/tracked-link';
import { TrackedLinkTemplate } from '@shared/models/tracked-link-template';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OpsEventTrackedLinksService {
  readonly #http = inject(HttpClient);
  readonly #baseUrl = `${environment.api.opsEvent}/v2/event`;

  getAvailableTrackedLinkTemplates(eventId: string): Observable<TrackedLinkTemplate[]> {
    return this.#http.get<TrackedLinkTemplate[]>(`${this.#baseUrl}/${eventId}/tracked-link/template`);
  }

  generateDefaultTrackedLinks(eventId: string, customDestination: string | null): Observable<unknown> {
    return this.#http.post(`${this.#baseUrl}/${eventId}/tracked-link/template/generate`, {
      customDestination,
    });
  }

  deleteTrackedLink(eventId: string, trackedLinkId: string): Observable<unknown> {
    return this.#http.delete(`${this.#baseUrl}/${eventId}/tracked-link/${trackedLinkId}`);
  }

  createTrackedLink(eventId: string, trackedLink: TrackedLink): Observable<unknown> {
    return this.#http.post(`${this.#baseUrl}/${eventId}/tracked-link`, trackedLink);
  }
}
