import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, NgZone, inject } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngxs/store';
import { MessageEventT } from '@shared/utils/sse/message-event-t';
import { SetSseToken } from '@store/user/user.action';
import { UserState } from '@store/user/user.state';
import { MercureCredentialsService } from '@webservices/ops-event-api/mercure-crendentials.service';
import * as _EventSourcePolyfill from 'event-source-polyfill';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

const EventSourcePolyfill = _EventSourcePolyfill.EventSourcePolyfill;

@Injectable({ providedIn: 'root' })
export class SseService {
  private readonly jwtHelperService: JwtHelperService;

  readonly #store = inject(Store);
  readonly #http = inject(HttpClient);
  readonly #mercureCredentialsService = inject(MercureCredentialsService);
  readonly #zone = inject(NgZone);

  constructor() {
    this.jwtHelperService = new JwtHelperService();
  }

  getServerSentEvent<T>(url: string): Observable<any> {
    return this.getToken().pipe(
      switchMap(
        (token) =>
          new Observable((observer) => {
            const eventSource = this.getEventSource(url, token);
            eventSource.onmessage = (event: MessageEventT<T>) => {
              this.#zone.run(() => {
                observer.next(event);
              });
            };

            eventSource.onerror = (event: MessageEventT<T>) => {
              // EventSource.OPEN         no error, connection opened
              // EventSource.CONNECTING   connecting or error occured but browser is trying to reconnect
              // EventSource.CLOSED       error was fatal
              if (event.target.readyState === EventSource.CLOSED) {
                this.#zone.run(() => {
                  observer.error(event);
                });
              }
            };
          })
      )
    );
  }

  sendMessage(url: string, topic: string, payload?: string): Observable<string> {
    return this.getToken().pipe(
      switchMap((token) =>
        this.#http.post(url, new HttpParams().set('topic', topic).set('data', JSON.stringify(payload || {})), {
          responseType: 'text',
          headers: new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', `Bearer ${token}`),
        })
      )
    );
  }

  private getToken(): Observable<string> {
    const token = this.#store.selectSnapshot(UserState.sseToken);

    if (!token || (!!token && this.jwtHelperService.isTokenExpired(token))) {
      return this.#mercureCredentialsService.get().pipe(
        map((newToken) => newToken.token),
        tap((newToken) => this.#store.dispatch(new SetSseToken(newToken)))
      );
    }

    return of(token);
  }

  private getEventSource(url: string, token?: string): any {
    return new EventSourcePolyfill(url, {
      headers: { ...(token ? { Authorization: `Bearer ${token}` } : {}) },
    });
  }
}
