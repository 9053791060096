@if (toast) {
  <div
    [class]="'fadeInUp toaster toaster--' + toast.type"
    [class.toaster--shift]="(isCallListEmpty$ | async) === false"
    (click)="hide(toast)"
    (keyup.enter)="hide(toast)"
    #toaster
    tabindex="0"
  >
    <div class="toaster__title">
      <mat-icon class="toaster__title__icon" [svgIcon]="toast.type === ToastType.DANGER ? 'warning' : 'check'" />
      {{ toast.title }}
    </div>
    @if (toast.message) {
      <p class="toaster__content" [innerHTML]="toast.message"></p>
    }
  </div>
}
