import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { UserRoleEnum } from '@shared/models/enums/user-roles-label.enum';
import { buildUrl, readBlob } from '@wizbii/angular-utilities';
import { WizFile } from '@wizbii/utils/models';
import { Observable, forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

interface UploadUrlResponse {
  uploadUrl: string;
  successUrl: string;
  fileId: string;
}

@Injectable({ providedIn: 'root' })
export class FileService {
  readonly #baseUrl = `${environment.api.file}/v1/file/?:id`;

  readonly #http = inject(HttpClient);

  create(file: WizFile, isFilePublic = false, additionalAllowedApps: any[] = []): Observable<WizFile> {
    const uploadUrlResponse$: Observable<UploadUrlResponse> = this.#http.post<UploadUrlResponse>(
      buildUrl(this.#baseUrl),
      {
        fileExtension: /\.(\w+)$/.exec(file.domFile.name)[1],
        contentType: file.domFile.type,
        public: isFilePublic,
        additionalAllowedApps,
        additionalAllowedRoles: [UserRoleEnum.USER, UserRoleEnum.SOURCING_MANAGER, UserRoleEnum.PROJECT_MANAGER],
      }
    );

    return forkJoin([readBlob(file.domFile), uploadUrlResponse$]).pipe(
      switchMap(([buffer, uploadUrlResponse]) => {
        return this.#http
          .put(uploadUrlResponse.uploadUrl, buffer, {
            headers: new HttpHeaders({ 'Content-Type': file.domFile.type }),
          })
          .pipe(
            switchMap(() => this.#http.post(uploadUrlResponse.successUrl, null)),
            map(() => new WizFile({ ...file, id: uploadUrlResponse.fileId }))
          );
      })
    );
  }

  getDownloadUrl(id: string): Observable<string> {
    return this.#http.get<{ url: string }>(buildUrl(this.#baseUrl, { id })).pipe(map((result) => result.url));
  }
}
