import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { User, toUser } from '@shared/models/user';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserService {
  private readonly baseUrl = `${environment.api.opsEvent}/api/users`;

  readonly #http = inject(HttpClient);

  getTasUsers(): Observable<User[]> {
    return this.#http
      .get<Record<string, unknown>[]>(`${this.baseUrl}/tas`)
      .pipe(map((users) => users.map((user) => new User(user))));
  }

  getAllUsers(): Observable<User[]> {
    return this.#http
      .get<Record<string, unknown>[]>(`${this.baseUrl}/all`)
      .pipe(map((users) => users.map((user) => new User(user))));
  }

  getUser(id: string): Observable<User> {
    return this.#http.get(`${this.baseUrl}/${id}`).pipe(map(toUser));
  }
}
