import { Injectable } from '@angular/core';

@Injectable()
export class ScrollLockService {
  static LOCK_CLASS = 'lock';

  lock(): void {
    document.documentElement.classList.add(ScrollLockService.LOCK_CLASS);
  }

  unlock(): void {
    document.documentElement.classList.remove(ScrollLockService.LOCK_CLASS);
  }
}
