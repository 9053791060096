import { SessionStateEnum } from '@shared/models/enums/session-state.enum';

export class SetSseToken {
  static readonly type = 'SetSseToken';
  constructor(public token: string) {}
}

export class GetUserProfile {
  static readonly type = '[OpsEventApi] GetUserProfile';
  constructor(public profileId: string) {}
}

export class DeleteUserToken {
  static readonly type = 'DeleteUserToken';
}

export class SetRedirectPage {
  static readonly type = 'Set redirect page';
  constructor(public redirectPage: string) {}
}

export class SetState {
  static readonly type = 'Set State';
  constructor(public state: SessionStateEnum) {}
}
