import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
  forwardRef,
  inject,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  standalone: true,
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  imports: [CommonModule, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchComponent implements ControlValueAccessor {
  private static count = 0;
  readonly id: number;
  @Output() modelChange = new EventEmitter<void>();

  @ViewChild('input', { static: true })
  inputElement: ElementRef<HTMLInputElement>;

  @Input() grouped? = false;

  @Input() hideLabel = false;

  @Input() showTitle = true;

  @Input() themeSuccess = false;

  @Input() disabled = false;

  @Output() toggle = new EventEmitter<boolean>();

  onChange: (_: boolean) => void = () => {};
  onTouched: () => void = () => {};

  @HostBinding('class.switch--activated')
  checked = false;

  readonly #cdr = inject(ChangeDetectorRef);

  constructor() {
    SwitchComponent.count++;
    this.id = SwitchComponent.count;
  }

  registerOnChange(fn: (_: boolean) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    this.#cdr.detectChanges();
  }

  writeValue(value: boolean): void {
    this.checked = !!value;
    this.#cdr.detectChanges();
  }

  clickEvent(event: Event): void {
    event.stopPropagation();
    this.checked = !this.inputElement.nativeElement.checked;
    this.onChange(this.checked);
    this.modelChange.emit();
  }
}
