import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import {
  CandidateNotificationResponseSubject,
  NotificationResponse,
  toCandidateNotificationResponse,
} from '@shared/models/notifications/notification-response';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OpsEventNotificationService {
  private readonly baseUrl = `${environment.api.opsEvent}/v2/notifications`;

  readonly #http = inject(HttpClient);

  getNotifications(): Observable<NotificationResponse<CandidateNotificationResponseSubject>[]> {
    return this.#http.get<Notification[]>(this.baseUrl).pipe(
      map((notifications) => notifications.map(toCandidateNotificationResponse)),
      map((notifications) =>
        [...notifications].sort((n1, n2) => new Date(n2.dateCreated).getTime() - new Date(n1.dateCreated).getTime())
      )
    );
  }

  setAsRead(notificationId: string): Observable<void> {
    return this.#http.patch<void>(`${this.baseUrl}/${notificationId}/read`, {});
  }
}
