import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import type { Call } from '@shared/models/voip/call';
import { CallStatusToLineStatusMappingEnum } from '@shared/models/voip/call-status.enum';
import type { Line } from '@shared/models/voip/line';
import { LineStatusEnum } from '@shared/models/voip/line-status.enum';
import { Calls } from '@shared/utils/voip/calls';
import { Lines } from '@shared/utils/voip/lines';
import {
  AddNewCall,
  CheckCallIsStillActive,
  ClearCall,
  InitializeLineStatus,
  UpdateCall,
  UpdateLineStateIfFree,
  UpdateLineStateToFirstCallStatus,
  UpdateUserStatus,
} from '@store/voip/voip.action';
import { VoipService } from '@webservices/voip-api/voip.service';
import { FeedbackToast, ToastType } from '@widgets/toaster/toaster';
import { ToasterService } from '@widgets/toaster/toaster.service';
import { BugsnagService } from '@wizbii/angular-bugsnag';
import { Observable, take } from 'rxjs';

interface VoipStateModel {
  online: boolean;
  line: Line;
  calls: Call[];
}

export const defaultState: VoipStateModel = {
  online: true,
  line: { licenceId: null, state: undefined, currentCallHandle: null },
  calls: [],
};
@State<VoipStateModel>({
  name: 'voip',
  defaults: defaultState,
})
@Injectable()
export class VoipState {
  readonly #voipService = inject(VoipService);
  readonly #toaster = inject(ToasterService);
  readonly #bugsnagClient = inject(BugsnagService);

  @Selector()
  static isOnline(state: VoipStateModel): boolean {
    return state?.online;
  }

  @Selector()
  static isLineFree(state: VoipStateModel): boolean {
    return Lines.isFree(state.line);
  }

  @Selector()
  static isCallListEmpty(state: VoipStateModel): boolean {
    return !state.calls?.length;
  }

  @Selector()
  static firstCall(state: VoipStateModel): Call {
    return state?.calls?.length ? state.calls[0] : null;
  }

  @Action(AddNewCall)
  addNewCall(ctx: StateContext<VoipStateModel>, { newCall }: AddNewCall): void {
    const currState = ctx.getState();
    if (Calls.exists(newCall.callHandle, currState.calls)) {
      return;
    }

    if (currState.online) {
      ctx.patchState({
        calls: this.addCallToSortedList(newCall, currState.calls),
        line: this.updateLineDueToNewCall(currState.line, newCall),
      });
    }
  }

  @Action(UpdateCall)
  updateCall(ctx: StateContext<VoipStateModel>, { callHandle, callStatus, callStart }: UpdateCall): void {
    let updatedCall = Calls.find(callHandle, ctx.getState().calls);
    if (!updatedCall) return;

    updatedCall = {
      ...updatedCall,
      callStatus: callStatus,
      callStart: callStart ?? updatedCall.callStart,
    };

    const unchangedCalls = this.removeCallFromList(callHandle, ctx.getState().calls);
    const updatedCalls = this.addCallToSortedList(updatedCall, unchangedCalls);
    ctx.patchState({ calls: updatedCalls, line: this.updateLineDueToUpdatedCall(ctx.getState().line, updatedCalls) });
    if (Calls.hasEnded(updatedCall)) {
      setTimeout(() => {
        ctx.patchState({
          calls: unchangedCalls,
          line: this.updateLineDueToRemovedCall(ctx.getState().line, unchangedCalls, callHandle),
        });
      }, 1000);
    }
  }

  @Action(ClearCall)
  clearCall(ctx: StateContext<VoipStateModel>, { callHandle }: ClearCall): void {
    const remainingCalls = this.removeCallFromList(callHandle, ctx.getState().calls);
    ctx.patchState({
      calls: remainingCalls,
      line: this.updateLineDueToRemovedCall(ctx.getState().line, remainingCalls, callHandle),
    });
  }

  @Action(InitializeLineStatus)
  initializeLineStatus(ctx: StateContext<VoipStateModel>, action: InitializeLineStatus): void {
    this.getLineStatus(action.voipLicenceId).subscribe({
      next: (line: Line) => {
        ctx.patchState({
          line: line,
          calls: this.retrieveCallListFromLineStatus(line),
        });
      },
      error: () => {
        this.#toaster.show(
          new FeedbackToast({
            type: ToastType.DANGER,
            title: 'Impossible de récupérer le statut de la ligne',
          })
        );
      },
    });
  }

  @Action(UpdateLineStateIfFree)
  updateLineStateIfFree(ctx: StateContext<VoipStateModel>, { state }: UpdateLineStateIfFree): void {
    const line = ctx.getState().line;
    if (Lines.isFree(line)) {
      ctx.patchState({ line: { ...line, state } });
    }
  }

  @Action(UpdateLineStateToFirstCallStatus)
  updateLineStateToFirstCallStatus(ctx: StateContext<VoipStateModel>): void {
    ctx.patchState({
      line: { ...ctx.getState().line, state: this.getStatusFromFirstCallStatus(ctx.getState().calls) },
    });
  }

  @Action(UpdateUserStatus)
  updateUserStatus(ctx: StateContext<VoipStateModel>, update: UpdateUserStatus): void {
    ctx.patchState({ online: update.online });
  }

  @Action(CheckCallIsStillActive)
  checkCallIsStillActive(ctx: StateContext<VoipStateModel>, { callHandle }: CheckCallIsStillActive): void {
    const licenceId = ctx.getState().line.licenceId;
    this.getLineStatus(licenceId).subscribe({
      next: (line: Line) => {
        const call = Calls.find(callHandle, this.retrieveCallListFromLineStatus(line));
        if (!call) {
          ctx.dispatch(new ClearCall(callHandle));
        } else {
          this.#bugsnagClient.sendError(`'[VoIP] Unable to terminate call ${callHandle} for licence ${licenceId}'`);
          this.#toaster.show(
            new FeedbackToast({
              type: ToastType.DANGER,
              title: "Impossible de terminer l'appel",
            })
          );
        }
      },
    });
  }

  private updateLineDueToNewCall(line: Line, newCall: Call): Line {
    if (!line || Lines.isFree(line) || Calls.isCurrent(newCall)) {
      return {
        licenceId: line.licenceId,
        currentCallHandle: newCall.callHandle,
        state: CallStatusToLineStatusMappingEnum[newCall.callStatus],
      };
    }
    return line;
  }

  private addCallToSortedList(newCall: Call, callList: Call[]): Call[] {
    return Calls.sortByStatus([...callList, newCall]);
  }

  private removeCallFromList(callHandle: string, callList: Call[]): Call[] {
    return callList.filter((call) => call.callHandle !== callHandle);
  }

  private updateLineDueToRemovedCall(line: Line, remainingCalls: Call[], removedCallHandle: string): Line {
    const currentCallHandle = line.currentCallHandle === removedCallHandle ? null : line.currentCallHandle;
    return {
      licenceId: line.licenceId,
      state: this.getStatusFromFirstCallStatus(remainingCalls),
      currentCallHandle,
    };
  }

  private updateLineDueToUpdatedCall(line: Line, callList: Call[]): Line {
    return {
      licenceId: line.licenceId,
      state: this.getStatusFromFirstCallStatus(callList),
      currentCallHandle: line.currentCallHandle,
    };
  }

  private retrieveCallListFromLineStatus(line: Line): Call[] {
    return line.currentCallHandle ? [Calls.buildAnonymous(line)] : [];
  }

  private getStatusFromFirstCallStatus(callList: Call[]): LineStatusEnum {
    return callList?.length ? CallStatusToLineStatusMappingEnum[callList[0].callStatus] : LineStatusEnum.Free;
  }

  private getLineStatus(voipLicenceId: string): Observable<Line> {
    return this.#voipService.getLineStatus(voipLicenceId).pipe(take(1));
  }
}
