import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, HostListener, Input, signal } from '@angular/core';
import { NotificationResponse } from '@shared/models/notifications/notification-response';
import { TimeAgoComponent } from '@widgets/time-ago/time-ago.component';

@Component({
  selector: 'app-notification',
  standalone: true,
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  imports: [CommonModule, TimeAgoComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
  @Input()
  notification?: NotificationResponse<any>;

  @Input()
  userId?: string;

  readonly isHovering = signal(false);

  @HostBinding('class.is-read')
  get isRead(): boolean {
    return !!(this.userId && this.notification?.readBy.includes(this.userId));
  }

  @HostBinding('class.is-treated')
  get isTreated(): boolean {
    return this.notification?.status === 'treated';
  }

  @HostBinding('class.is-hover')
  get isHover(): boolean {
    return this.isHovering();
  }

  @HostListener('mouseover')
  onMouseOver(): void {
    this.isHovering.set(true);
  }

  @HostListener('mouseout')
  onMouseOut(): void {
    this.isHovering.set(false);
  }
}
