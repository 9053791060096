import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngxs/store';
import { DeleteUserToken } from '@store/user/user.action';
import { JwtServiceInterface, JwtTokens } from '@wizbii-utils/angular/jwt';
import { JwtSet, JwtState } from '@wizbii-utils/angular/stores';
import { AuthenticationWebservice } from '@wizbii-utils/angular/webservices';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class JwtService implements JwtServiceInterface {
  blacklistRoutes: string[] = [
    environment.api.authentication,
    environment.api.googleStorage,
    environment.api.mercureHubBaseUrl,
  ];

  readonly #store = inject(Store);
  readonly #authWebservice = inject(AuthenticationWebservice);

  getTokens(): Observable<JwtTokens> {
    return this.#store.select(JwtState);
  }

  logout(): void {
    this.#store.dispatch(new DeleteUserToken());
  }

  refreshToken(tokens: JwtTokens): Observable<JwtTokens> {
    return this.#authWebservice.refreshToken(tokens);
  }

  saveLocalTokens(tokens: JwtTokens): void {
    this.#store.dispatch(new JwtSet(tokens));
  }
}
