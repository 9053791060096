import { AnimationMetadata, animate, keyframes, style } from '@angular/animations';

export function generateBouncyTransition(from: number, to: number): AnimationMetadata[] {
  return [
    animate(
      '450ms ease-out',
      keyframes([
        style({ transform: `scale(${from})` }),
        style({ transform: `scale(${from + 0.3})` }),
        style({ transform: `scale(${to + 0.3})` }),
        style({ transform: `scale(${to})` }),
      ])
    ),
  ];
}
