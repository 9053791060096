import { buildConfig } from './build-config';
import { getBuildEnvVar, getRuntimeEnvVar } from '@wizbii/angular-utilities';

// eslint-disable-line

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const isProdBuild = buildConfig === 'production';
const version = process.env.VERSION;

// Determine deployment platform and domain from Ansible vars
const platform = getRuntimeEnvVar('PLATFORM');
const apiPlatform = getRuntimeEnvVar('API_PLATFORM');
const wizbiiDomain = getRuntimeEnvVar('WIZBII_ENV_FQDN');
const wizbiiTeamDomain = getRuntimeEnvVar('WIZBII_TEAM_ENV_FQDN');
const wizbiiFiles = getRuntimeEnvVar('FILE_API_BUCKET');
const trackingId = getRuntimeEnvVar('WIZBII_ANALYTICS_UAI');
const deployAssetsUrl = isProdBuild
  ? `https://storage.googleapis.com/wizbii-application-assets/sourcii/${getBuildEnvVar('CI_COMMIT_SHA')}/fr/assets`
  : null;
const isDeployed = !!platform;

const sourcii = window.location.origin;

export const environment = {
  applicationId: 'sourcii',
  deployAssetsUrl: window.location.hostname === 'localhost' ? `http://${window.location.host}/assets` : deployAssetsUrl,
  platform,
  domain: {
    wizbii: wizbiiDomain,
    wizbiiTeam: wizbiiTeamDomain,
    cookie: window.location.hostname === 'localhost' ? 'localhost' : wizbiiTeamDomain,
  },
  isDeployed,
  siteUrl: isDeployed ? `https://${platform === 'prod' ? 'www' : 'jobs'}.${wizbiiDomain}` : '',
  adminUrl: isDeployed ? `https://admin.${wizbiiDomain}` : '',
  isProdBuild,
  version,
  bugsnagId: '0e0e50ea90d45211c0ac526dd9ac884c',
  api: {
    account: `https://account.api.${wizbiiDomain}`,
    authentication: `https://auth.${wizbiiDomain}`,
    company: `https://company-api.${wizbiiDomain}`,
    cvBook: `https://cv-book.event.${wizbiiDomain}`,
    eventApp: `https://event.${wizbiiDomain}`,
    file: `https://file.${wizbiiDomain}`,
    googleStorage: 'https://storage.googleapis.com',
    imaginary: `https://imaginary.${wizbiiDomain}`,
    job: `https://job-api.${wizbiiDomain}`,
    mercureHubBaseUrl: `https://mercure-hub.${wizbiiDomain}`,
    mercure: `https://mercure-hub.${wizbiiDomain}/.well-known/mercure`,
    message: `https://message.api.${wizbiiDomain}`,
    monitor: `https://monitor.api.${wizbiiDomain}`,
    opsEvent: `https://ops-event.api.${wizbiiDomain}`,
    place: `https://place.${wizbiiDomain}`,
    profile: `https://profile-api.${wizbiiDomain}`,
    search: `https://search.${wizbiiDomain}`,
    school: `https://school-api.${wizbiiDomain}`,
    sso: `https://sso.internal.${wizbiiDomain}`,
    email: `https://email.api.${wizbiiDomain}`,
    voip: `https://voip-api.${wizbiiDomain}`,
    webMetrics: `https://www.${wizbiiDomain}/_api/web-metrics-api`,
  },
  urls: {
    ssoRedirectUrl: `${
      platform !== 'local' ? sourcii : 'https://localhost.0' // sso-internal and Auth API have strict domain rules
    }/sign-in`,
  },
  wizbiiFiles: wizbiiFiles,
  trackingId: trackingId,
  algolia: {
    index: {
      events: `${apiPlatform}_opsevent_event`,
      candidates: `${apiPlatform}_opsevent_candidate_participation`,
      companies: `${apiPlatform}_opsevent_company`,
      recruiters: `${apiPlatform}_opsevent_recruiter`,
      wizbiiCompanies: `${apiPlatform}_companies`,
    },
  },
};
