import { transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, inject, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterModule } from '@angular/router';
import { NotificationComponent } from '@core/components/header/notification/notification.component';
import { environment } from '@environment';
import { EventDetailRoutingEnum } from '@features/events/event-detail/event-detail-routing.enum';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Store } from '@ngxs/store';
import { generateBouncyTransition } from '@shared/animations/bouncy';
import { CandidateDetailTabsEnum } from '@shared/models/enums/candidate-detail-tabs.enum';
import {
  DownloadReadyNotificationResponseSubject,
  JobReviewedEnum,
  NewJobOfferNotificationResponseSubject,
  NotificationResponse,
  NotificationResponseSubject,
  NotificationTypeEnum,
  toNotificationResponse,
} from '@shared/models/notifications/notification-response';
import { SseService } from '@shared/services/sse.service';
import { GetCompanyList } from '@store/company-list/company-list.action';
import { HandleNotification, MarkAsRead } from '@store/notifications/notifications.action';
import { NotificationsState } from '@store/notifications/notifications.state';
import { OpsEventDetailState } from '@store/ops-event-detail/ops-event-detail.state';
import { FileService } from '@webservices/file-api/file.service';
import { PopupModule } from '@widgets/popup/popup.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'app-notifications-pop-up',
  templateUrl: './notifications-pop-up.component.html',
  styleUrls: ['./notifications-pop-up.component.scss'],
  imports: [CommonModule, PopupModule, RouterModule, MatIconModule, NotificationComponent, NgScrollbarModule],
  animations: [
    trigger('pop', [
      transition(':enter', generateBouncyTransition(0, 1)),
      transition(':leave', generateBouncyTransition(1.5, 0)),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsPopUpComponent implements OnInit {
  readonly #store = inject(Store);
  readonly #sseService = inject(SseService);
  readonly #router = inject(Router);
  readonly #fileService = inject(FileService);

  @Input()
  userId?: string;

  readonly notifications$: Observable<NotificationResponse<any>[]> = this.#store.select(NotificationsState);
  readonly eventId$: Observable<string> = this.#store.select(OpsEventDetailState.eventId);

  readonly notificationCount$ = this.notifications$.pipe(
    map((n) => n.filter((notification) => notification.status !== 'treated').length)
  );

  readonly mustClosePopupOnClick = signal(false);

  readonly FeaturesRoutingEnum = FeaturesRoutingEnum;
  readonly EventDetailRoutingEnum = EventDetailRoutingEnum;
  readonly CandidateDetailTabsEnum = CandidateDetailTabsEnum;
  readonly NotificationTypeEnum = NotificationTypeEnum;
  readonly JobReviewedEnum = JobReviewedEnum;

  timeoutSaved: any;

  ngOnInit(): void {
    this.#sseService
      .getServerSentEvent(`${environment.api.mercure}?topic=http://sourcii/notification/${this.userId}`)
      .pipe(mergeMap((mercureUpdate) => this.eventId$.pipe(map((eventId) => ({ mercureUpdate, eventId })))))
      .subscribe({
        next: ({ mercureUpdate, eventId }) => {
          const notification = toNotificationResponse(JSON.parse(mercureUpdate.data));
          if (notification.recipients.includes(this.userId)) {
            this.#store.dispatch(new HandleNotification(notification));
          }
          if (
            [NotificationTypeEnum.jobOfferReviewed, NotificationTypeEnum.jobOfferPendingValidation].includes(
              notification.subject.type
            ) &&
            eventId
          ) {
            this.#store.dispatch(new GetCompanyList(eventId));
          }
        },
      });
  }

  downloadFileAndSetAsTreated(notification: NotificationResponse<DownloadReadyNotificationResponseSubject>): void {
    this.#fileService
      .getDownloadUrl(notification.subject.fileId)
      .subscribe({ next: (url) => window.open(url, '_blank') });

    this.markAsRead(notification);
  }

  openJobOfferAndSetAsTreated(notification: NotificationResponse<NewJobOfferNotificationResponseSubject>): void {
    this.#router.navigate(
      ['/', FeaturesRoutingEnum.Events, notification.subject.eventId, EventDetailRoutingEnum.jobs],
      {
        fragment: notification.subject.companyId,
      }
    );
    this.markAsRead(notification);
  }

  markAsRead(notification: NotificationResponse<NotificationResponseSubject>): void {
    this.mustClosePopupOnClick.set(
      [
        NotificationTypeEnum.eventParticipationConfirmation,
        NotificationTypeEnum.sms,
        NotificationTypeEnum.downloadReady,
        NotificationTypeEnum.jobOfferPendingValidation,
        NotificationTypeEnum.jobOfferReviewed,
      ].includes(notification.subject.type)
    );

    this.#store.dispatch(new MarkAsRead(notification));
  }

  openChangePopup(isOpen: boolean): void {
    if (!isOpen) {
      clearTimeout(this.timeoutSaved);
    }
  }
}
