import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

enum ModeEnum {
  Long = 'long',
  Short = 'short',
}
@Component({
  standalone: true,
  selector: 'app-time-ago',
  templateUrl: './time-ago.component.html',
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeAgoComponent {
  _date: Date;
  years: number;
  months: number;
  days: number;
  hours: number;
  minutes: number;
  modeEnum = ModeEnum;

  @Input() mode: ModeEnum = ModeEnum.Long;

  @Input()
  set date(date: Date) {
    this._date = date;

    if (date) {
      const numberOfMillisecondsInYears = 365.25 * 30.4375 * 24 * 60 * 60 * 1000;
      const numberOfMillisecondsInMonths = 30.4375 * 24 * 60 * 60 * 1000;
      const numberOfMillisecondsInDays = 24 * 60 * 60 * 1000;
      const numberOfMillisecondsInHours = 60 * 60 * 1000;
      const numberOfMillisecondsInMinute = 60 * 1000;

      const timeAgo = Date.now() - date.getTime();

      this.years = Math.floor(timeAgo / numberOfMillisecondsInYears);
      this.months = Math.floor(timeAgo / numberOfMillisecondsInMonths);
      this.days = Math.floor(timeAgo / numberOfMillisecondsInDays);
      this.hours = Math.floor(timeAgo / numberOfMillisecondsInHours);
      this.minutes = Math.floor(timeAgo / numberOfMillisecondsInMinute);
    }
  }
}
