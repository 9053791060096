import { transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngxs/store';
import { generateBouncyTransition } from '@shared/animations/bouncy';
import { User } from '@shared/models/user';
import { UserState } from '@store/user/user.state';
import { VoipService } from '@webservices/voip-api/voip.service';
import { Observable, switchMap } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-voice-mail',
  templateUrl: './voice-mail.component.html',
  styleUrls: ['./voice-mail.component.scss'],
  imports: [CommonModule, MatIconModule],
  animations: [
    trigger('pop', [
      transition(':enter', generateBouncyTransition(0, 1)),
      transition(':leave', generateBouncyTransition(1.5, 0)),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VoiceMailComponent {
  readonly #store = inject(Store);
  readonly #voipService = inject(VoipService);

  readonly tasProfile$: Observable<User> = this.#store.select(UserState.userProfile);
  readonly voiceMsgNum$: Observable<{ nbMessage: number } | undefined> = this.tasProfile$.pipe(
    switchMap((tasProfile) => this.#voipService.getVoiceMailMsg(tasProfile.licenceId))
  );
}
