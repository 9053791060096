import { Injectable, inject } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { SseServiceInterface } from '@shared/services/sse-interceptor/sse.service.interface';
import { DeleteUserToken, SetSseToken } from '@store/user/user.action';
import { UserState } from '@store/user/user.state';
import { MercureCredentialsService } from '@webservices/ops-event-api/mercure-crendentials.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SseInterceptorService implements SseServiceInterface {
  @Select(UserState.sseToken)
  token$: Observable<string>;

  blacklistRoutes: string[] = [];

  readonly #store = inject(Store);
  readonly #mercureCredentialsService = inject(MercureCredentialsService);

  getToken(): Observable<string> {
    return this.token$;
  }

  logout(): void {
    this.#store.dispatch(new DeleteUserToken());
  }

  refreshToken(): Observable<string> {
    return this.#mercureCredentialsService.get().pipe(map((token) => token.token));
  }

  saveLocalToken(token: string): void {
    this.#store.dispatch(new SetSseToken(token));
  }
}
