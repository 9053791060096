import { CandidateCommunicationActionEnum } from '@shared/models/enums/candidate-communication-action.enum';

export class CandidateContactAction {
  type: CandidateCommunicationActionEnum;
  comment: string;
  author: string | null;
  dateCreated: Date;

  constructor(data: Record<string, any>) {
    this.type = data['type'];
    this.comment = data['comment'];
    this.author = data['author'];
    this.dateCreated = new Date(data['dateCreated']);
  }
}
