import { Answer } from '@shared/models/answer';
import { CandidateComment } from '@shared/models/candidate-comment';
import { CandidateContactAction } from '@shared/models/candidate-contact-action';
import { CandidateHistory } from '@shared/models/candidate-history';
import { CandidateRecentActivity } from '@shared/models/candidate-recent-activity';
import { CandidateProfileEnum } from '@shared/models/enums/candidate-profile.enum';
import { StatusCandidateEnum } from '@shared/models/enums/status-candidate.enum';
import { StatusParticipationEnum } from '@shared/models/enums/status-participation.enum';
import { Sms, toSms } from '@shared/models/sms';
import { UnavailabilitySlot } from '@wizbii/utils/models';

export enum TransferMode {
  copy = 'copy',
  move = 'move',
}

export class CandidateParticipation {
  id: string;
  eventId: string;
  firstName: string;
  lastName: string;
  mail: string;
  phone: string;
  jobsIds: string[];
  statusParticipation: StatusParticipationEnum;
  statusCandidate: StatusCandidateEnum;
  resumeFileId: string;
  candidateRecentActivities: CandidateRecentActivity[];
  candidateHistory: CandidateHistory[];
  candidateComments: CandidateComment[];
  candidateContactActions: CandidateContactAction[];
  profileId: string;
  sourcePlatform: string;
  sourceOrigin: string;
  author: string;
  messageId: string;
  owner: string;
  emailSent: boolean;
  dateModified: Date;
  birthday: Date | null;
  answers: Answer[];
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  utmTerm: string;
  utmContent: string;
  smsList: Sms[];
  rejectedReason: string;
  wantedJob: string;
  jobDomain: string;
  experienceYears: number;
  motivations: string;
  educationLevel: string;
  driverLicence: boolean;
  profile?: CandidateProfileEnum;
  client?: boolean;
  unavailabilitySlots?: UnavailabilitySlot[];

  constructor(data: Record<string, any>) {
    this.id = data['id'];
    this.firstName = data['firstName'];
    this.lastName = data['lastName'];
    this.eventId = data['eventId'];
    this.mail = data['mail'];
    this.phone = data['phone'];
    this.jobsIds = data['jobsIds'];
    this.statusParticipation = data['statusParticipation'] as StatusParticipationEnum;
    this.statusCandidate = data['statusCandidate'] as StatusCandidateEnum;
    this.candidateRecentActivities = (data['activities'] || []).map(
      (activity) => new CandidateRecentActivity(activity)
    );
    this.candidateHistory = (data['history'] || []).map((historyLine: any) => new CandidateHistory(historyLine));
    this.candidateComments = (data['comments'] || []).map((comment: any) => new CandidateComment(comment));
    this.candidateContactActions = (data['contactActions'] || []).map((action) => new CandidateContactAction(action));
    this.profileId = data['profileId'];
    this.sourcePlatform = data['sourcePlatform'];
    this.sourceOrigin = data['sourceOrigin'];
    this.resumeFileId = data['resumeFileId'];
    this.author = data['author'];
    this.messageId = data['messageId'];
    this.owner = data['owner'];
    this.emailSent = data['emailSent'];
    this.dateModified = data['dateModified'] ? new Date(data['dateModified']) : new Date();
    this.birthday = data['birthday'] ? new Date(data['birthday']) : null;
    this.answers = data['answers'] || [];
    this.utmSource = data['utmSource'];
    this.utmMedium = data['utmMedium'];
    this.utmCampaign = data['utmCampaign'];
    this.utmTerm = data['utmTerm'];
    this.utmContent = data['utmContent'];
    this.smsList = (data['smsList'] || []).map(toSms);
    this.rejectedReason = data['rejectedReason'];
    this.wantedJob = data['wantedJob'];
    this.jobDomain = data['jobDomain'];
    this.experienceYears = data['experienceYears'];
    this.motivations = data['motivations'];
    this.educationLevel = data['educationLevel'];
    this.driverLicence = data['driverLicence'];
    this.profile = data['profile'];
    this.client = data['client'];
    this.unavailabilitySlots = data['unavailabilitySlots'];
  }
}
