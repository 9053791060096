import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { CandidateParticipation } from '@shared/models/candidate-participation';
import { Company, CompanyMinimalData } from '@shared/models/company';
import { OpsEventBlocEnum } from '@shared/models/enums/ops-event-bloc.enum';
import { EventDashboardFilter } from '@shared/models/event-dashboard-filter';
import { LoadingStatus } from '@shared/models/loading-status';
import type { OpsEventLoadingStatus } from '@shared/models/loading-status.type';
import { OpsEvent } from '@shared/models/ops-event';
import type { OriginUtm } from '@shared/models/origin-utm';
import { TrackedLink } from '@shared/models/tracked-link';
import { User } from '@shared/models/user';
import { getNumberFromLicenceId, prettyPrintNumber } from '@shared/utils/voip/voip-number';
import { DeleteEventPlanning, GenerateEventPlanning } from '@store/company-list/company-list.action';
import {
  CreateTrackedLink,
  DeleteEvent,
  DeleteTrackedLink,
  DownloadEventConsentCsvExtract,
  DownloadEventDetailExtract,
  DownloadEventDetailQuestionExtract,
  DownloadEventImageConsentPdf,
  DownloadEventJobList,
  EmptyEventDetail,
  GenerateDefaultTrackedLinks,
  GetContracts,
  GetDegrees,
  GetEvent,
  GetEventDetailCompany,
  GetEventDetailDistinctUtm,
  GetEventDetailSearchResult,
  GetEventDetailSourcePlatform,
  GetTasUsersList,
  InitCreateEventState,
  SaveFileBeforeSaveEvent,
  ShowCreateEventBadgeFile,
  ShowCreateEventCandidateGuideFile,
  ShowCreateEventInvitationFile,
  ShowCreateEventSummaryFile,
  UpdateOpsEvent,
} from '@store/ops-event-detail/ops-event-detail.action';
import { defaultLoadingStatus } from '@store/utils/loading-status-default';
import { FileService } from '@webservices/file-api/file.service';
import { CandidateDetailService } from '@webservices/ops-event-api/candidate-detail.service';
import { CompanyService } from '@webservices/ops-event-api/company.service';
import { OpsEventDetailService } from '@webservices/ops-event-api/ops-event-detail.service';
import { OpsEventTrackedLinksService } from '@webservices/ops-event-api/ops-event-tracked-links.service';
import { SearchCandidateListService } from '@webservices/search-api/search-candidate-list.service';
import { UserService } from '@webservices/user-api/user.service';
import { VoipService } from '@webservices/voip-api/voip.service';
import { FeedbackToast, ToastType } from '@widgets/toaster/toaster';
import { ToasterService } from '@widgets/toaster/toaster.service';
import { ContractWebservice, DegreeWebservice } from '@wizbii-utils/angular/webservices';
import { downloadFile } from '@wizbii/angular-utilities';
import { Contract, Degree, LocaleEnum } from '@wizbii/utils/models';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

export interface OpsEventDetailStateModel {
  event: OpsEvent | null;
  company: CompanyMinimalData | null;
  loadingStatus: OpsEventLoadingStatus;
  searchResult: CandidateParticipation[];
  tasList: User[] | null;
  sourcePlatform: OriginUtm;
  contracts: Contract[] | null;
  degrees: Degree[] | null;
  distinctFilters: EventDashboardFilter[];
  mustUpdate: boolean;
  loading: boolean;
  error: HttpErrorResponse | null;
}

const defaultState: OpsEventDetailStateModel = {
  event: new OpsEvent({}),
  company: null,
  loadingStatus: {
    [OpsEventBlocEnum.event]: defaultLoadingStatus,
    [OpsEventBlocEnum.trackedLink]: defaultLoadingStatus,
  },
  searchResult: [],
  tasList: null,
  sourcePlatform: {},
  contracts: null,
  degrees: null,
  distinctFilters: [],
  mustUpdate: false,
  loading: false,
  error: null,
};

@State<OpsEventDetailStateModel>({
  name: 'opsEventDetail',
  defaults: defaultState,
})
@Injectable()
export class OpsEventDetailState {
  @Selector()
  static error(state: OpsEventDetailStateModel): HttpErrorResponse | null {
    return state?.error;
  }

  @Selector()
  static loading(state: OpsEventDetailStateModel): boolean {
    return state?.loading;
  }

  @Selector()
  static mustUpdate(state: OpsEventDetailStateModel): boolean {
    return state?.mustUpdate;
  }

  @Selector()
  static distinctFilters(state: OpsEventDetailStateModel): EventDashboardFilter[] {
    return state?.distinctFilters;
  }

  @Selector()
  static degrees(state: OpsEventDetailStateModel): Degree[] | null {
    return state?.degrees;
  }

  @Selector()
  static contracts(state: OpsEventDetailStateModel): Contract[] | null {
    return state?.contracts;
  }

  @Selector()
  static sourcePlatform(state: OpsEventDetailStateModel): OriginUtm {
    return state?.sourcePlatform;
  }

  @Selector()
  static tasList(state: OpsEventDetailStateModel): User[] | null {
    return state?.tasList;
  }

  @Selector()
  static searchResult(state: OpsEventDetailStateModel): CandidateParticipation[] {
    return state?.searchResult;
  }

  @Selector()
  static loadingStatus(state: OpsEventDetailStateModel): OpsEventLoadingStatus {
    return state?.loadingStatus;
  }

  @Selector()
  static loadedStatus(state: OpsEventDetailStateModel): boolean | undefined {
    return state?.loadingStatus[OpsEventBlocEnum.event]?.loaded;
  }

  @Selector()
  static eventConfirmedCandidates(state: OpsEventDetailStateModel): number | undefined {
    return state?.event?.confirmedCandidates;
  }

  @Selector()
  static eventTrackedLinks(state: OpsEventDetailStateModel): TrackedLink[] | undefined {
    return state?.event?.trackedLinks;
  }

  @Selector()
  static eventId(state: OpsEventDetailStateModel): string | undefined {
    return state?.event?.id;
  }

  @Selector()
  static event(state: OpsEventDetailStateModel): OpsEvent | null {
    return state?.event;
  }

  @Selector()
  static company(state: OpsEventDetailStateModel): CompanyMinimalData | null {
    return state?.company;
  }

  @Selector()
  static updatedStatus(state: OpsEventDetailStateModel): boolean | undefined {
    return state?.loadingStatus[OpsEventBlocEnum.event]?.updated;
  }

  @Selector()
  static updatingStatus(state: OpsEventDetailStateModel): boolean | undefined {
    return state?.loadingStatus[OpsEventBlocEnum.event]?.updating;
  }

  @Selector()
  static opsEvent(state: OpsEventDetailStateModel): OpsEvent | null {
    return state?.event;
  }

  readonly #store = inject(Store);
  readonly #opsEventService = inject(OpsEventDetailService);
  readonly #candidateDetailService = inject(CandidateDetailService);
  readonly #companyService = inject(CompanyService);
  readonly #toaster = inject(ToasterService);
  readonly #fileService = inject(FileService);
  readonly #voipService = inject(VoipService);
  readonly #userService = inject(UserService);
  readonly #contractWebservice = inject(ContractWebservice);
  readonly #searchCandidateListService = inject(SearchCandidateListService);
  readonly #degreeWebservice = inject(DegreeWebservice);
  readonly #trackedLinkService = inject(OpsEventTrackedLinksService);

  @Action(GetEvent)
  GetEvent(ctx: StateContext<OpsEventDetailStateModel>, action: GetEvent): any {
    this.updatePartialLoadingStatus(ctx, OpsEventBlocEnum.event, {
      loading: true,
      loaded: false,
    });

    return this.#opsEventService.getEvent(action.eventId).pipe(
      tap((event) => {
        ctx.patchState({ event });
        if (event.wizCompanyId) {
          this.#store.dispatch(new GetEventDetailCompany(event.wizCompanyId));
        }
        this.updatePartialLoadingStatus(ctx, OpsEventBlocEnum.event, {
          loading: false,
          loaded: true,
        });
      })
    );
  }

  @Action(GetEventDetailSourcePlatform)
  getEventDetailSourcePlatform(ctx: StateContext<OpsEventDetailStateModel>): any {
    return this.#opsEventService.getSourcePlatform().pipe(
      tap((sourcePlatform) => {
        ctx.patchState({ sourcePlatform });
      }),
      catchError((error) => this.toasterShow(error))
    );
  }

  @Action(GetEventDetailSearchResult)
  getEventDetailSearchResult(
    ctx: StateContext<OpsEventDetailStateModel>,
    { query, eventId }: GetEventDetailSearchResult
  ): any {
    return this.#searchCandidateListService.getFilteredCandidatesIdsForEvent(query, eventId).pipe(
      switchMap((candidatesIds) => this.#candidateDetailService.getCandidatesForEventByIds(eventId, candidatesIds)),
      map((candidates) => {
        ctx.patchState({
          searchResult: candidates,
        });
      }),
      catchError((error) => this.toasterShow(error))
    );
  }

  @Action(EmptyEventDetail)
  emptyEventDetail(ctx: StateContext<OpsEventDetailStateModel>): void {
    ctx.patchState({ event: null, company: null });
  }

  @Action(DownloadEventDetailExtract)
  downloadEventDetailExtract(_: StateContext<OpsEventDetailStateModel>, action: DownloadEventDetailExtract): any {
    return this.#opsEventService.downloadExtractFile(action.eventId).pipe(
      map((res) => {
        downloadFile(res.blob, res.filename);
      })
    );
  }

  @Action(DownloadEventDetailQuestionExtract)
  downloadEventDetailQuestionExtract(
    _: StateContext<OpsEventDetailStateModel>,
    action: DownloadEventDetailQuestionExtract
  ): any {
    return this.#opsEventService.downloadExtractQuestion(action.eventId).pipe(
      map((res) => {
        downloadFile(res.blob, res.filename);
      })
    );
  }

  @Action(DownloadEventConsentCsvExtract)
  downloadEventConsentListing(_: StateContext<OpsEventDetailStateModel>, action: DownloadEventConsentCsvExtract): any {
    return this.#opsEventService.doDownloadConsentExtract(action.eventId).pipe(
      map((res) => {
        downloadFile(res.blob, res.filename);
      })
    );
  }

  @Action(DownloadEventJobList)
  downloadEventJobList(_: StateContext<OpsEventDetailStateModel>, action: DownloadEventConsentCsvExtract): any {
    return this.#opsEventService.doDownloadJobList(action.eventId).pipe(
      map((res) => {
        downloadFile(res.blob, res.filename);
      })
    );
  }

  @Action(DownloadEventImageConsentPdf)
  downloadEventDetailConsent(_: StateContext<OpsEventDetailStateModel>, action: DownloadEventImageConsentPdf): any {
    return this.#opsEventService
      .requestImageConsentDownload(action.eventId)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.#toaster.show(
            new FeedbackToast({
              type: ToastType.SUCCESS,
              title: 'Fichier en cours de génération',
              message:
                'Vous recevrez une notification quand le PDF sera disponible. Cliquez sur la notification pour le télécharger.',
              duration: 5000,
            })
          );
        },
        error: () => {
          this.#toaster.show(
            new FeedbackToast({
              type: ToastType.DANGER,
              title: 'Téléchargement du fichier impossible',
              message: 'La demande de téléchargement a échoué',
              duration: 2000,
            })
          );
        },
      });
  }

  @Action(GetTasUsersList)
  getTasUsersList(ctx: StateContext<OpsEventDetailStateModel>): any {
    return forkJoin([this.#userService.getTasUsers(), this.#voipService.listLicences()]).pipe(
      tap(([users, licences]) => {
        ctx.patchState({
          tasList: users.map((user) => {
            const phoneNumber = getNumberFromLicenceId(licences, user.licenceId);
            return new User({
              ...user,
              voipNumber: phoneNumber ? prettyPrintNumber(phoneNumber) : null,
            });
          }),
        });
      })
    );
  }

  @Action(GenerateDefaultTrackedLinks)
  generateDefaultTrackedLinks(_: StateContext<OpsEventDetailStateModel>, action: GenerateDefaultTrackedLinks): any {
    return this.#trackedLinkService.generateDefaultTrackedLinks(action.eventId, action.customDestination).pipe(
      tap(() => {
        this.#store.dispatch(new GetEvent(action.eventId));
      })
    );
  }

  @Action(DeleteTrackedLink)
  deleteTrackedLink(ctx: StateContext<OpsEventDetailStateModel>, action: DeleteTrackedLink): any {
    this.updatePartialLoadingStatus(ctx, OpsEventBlocEnum.trackedLink, {
      deleting: true,
      deleted: false,
    });

    return this.#trackedLinkService.deleteTrackedLink(action.eventId, action.trackedLinkId).pipe(
      tap(() => {
        this.#store.dispatch(new GetEvent(action.eventId));
        this.updatePartialLoadingStatus(ctx, OpsEventBlocEnum.trackedLink, {
          deleting: false,
          deleted: false,
        });
      })
    );
  }

  @Action(GetEventDetailCompany)
  getEventDetailCompany(
    ctx: StateContext<OpsEventDetailStateModel>,
    action: GetEventDetailCompany
  ): Observable<CompanyMinimalData> {
    return this.#companyService.getCompany(action.companyId).pipe(
      tap((company: Company) => {
        ctx.patchState({
          company: {
            id: company.id,
            name: company.name,
            location: company.location,
            isClient: company.isClient,
            status: company.status,
          } as CompanyMinimalData,
        });
      })
    );
  }

  @Action(CreateTrackedLink)
  createTrackedLink(ctx: StateContext<OpsEventDetailStateModel>, action: CreateTrackedLink): any {
    this.updatePartialLoadingStatus(ctx, OpsEventBlocEnum.trackedLink, {
      updating: true,
      updated: false,
    });

    return this.#trackedLinkService.createTrackedLink(action.eventId, action.trackedLink).pipe(
      tap(() => {
        this.#store.dispatch(new GetEvent(action.eventId));
        this.updatePartialLoadingStatus(ctx, OpsEventBlocEnum.trackedLink, {
          updating: false,
          updated: false,
        });
      }),
      catchError((err) => {
        this.updatePartialLoadingStatus(ctx, OpsEventBlocEnum.trackedLink, {
          updating: false,
          updated: false,
        });
        return this.toasterShow(err);
      })
    );
  }

  @Action(GetEventDetailDistinctUtm)
  getEventDetailDistinctUtm(ctx: StateContext<OpsEventDetailStateModel>, action: GetEventDetailDistinctUtm): any {
    return this.#opsEventService.getDistinctUtm(action.eventId).pipe(
      map((distinctFilters: EventDashboardFilter[]) => {
        ctx.patchState({ distinctFilters });
      })
    );
  }

  @Action(GetContracts)
  getContracts(ctx: StateContext<OpsEventDetailStateModel>): any {
    if (!ctx.getState().contracts) {
      return this.#contractWebservice.get(LocaleEnum.fr_FR).pipe(
        tap((contracts) => {
          ctx.patchState({ contracts });
        })
      );
    }
  }

  @Action(GetDegrees)
  getDegrees(ctx: StateContext<OpsEventDetailStateModel>): any {
    if (!ctx.getState().degrees) {
      return this.#degreeWebservice.get(LocaleEnum.fr_FR).pipe(
        tap((degrees) => {
          ctx.patchState({ degrees });
        })
      );
    }
  }
  @Action(DeleteEvent)
  deleteEvent(_: StateContext<OpsEventDetailStateModel>, action: DeleteEvent): any {
    return this.#opsEventService
      .deleteEvent(action.eventId)
      .pipe(switchMap(() => this.#store.dispatch(new Navigate(['events']))));
  }

  /* eslint-disable sonarjs/cognitive-complexity */
  @Action(SaveFileBeforeSaveEvent)
  async saveFilesBeforeSaveEvent(
    ctx: StateContext<OpsEventDetailStateModel>,
    { files }: SaveFileBeforeSaveEvent
  ): Promise<any> {
    this.updatePartialLoadingStatus(ctx, OpsEventBlocEnum.event, {
      updating: true,
      updated: false,
    });
    let newEvent: OpsEvent | null = this.#store.selectSnapshot(OpsEventDetailState.opsEvent);

    if (files?.thumbnail?.domFile) {
      const file = await this.#fileService.create(files.thumbnail, true).toPromise();
      if (file) {
        newEvent = {
          ...newEvent,
          thumbnailFileId: file.id,
        };
      }
    }

    if (files?.banner?.domFile) {
      const file = await this.#fileService.create(files.banner, true).toPromise();
      if (file) {
        newEvent = {
          ...newEvent,
          bannerFileId: file.id,
        };
      }
    }

    if (files?.smallSocialNetwork?.domFile) {
      const file = await this.#fileService.create(files.smallSocialNetwork, true).toPromise();

      if (file) {
        newEvent = {
          ...newEvent,
          smallSocialNetworkFileId: file?.id,
        };
      }
    }

    if (files?.bigSocialNetwork?.domFile) {
      const file = await this.#fileService.create(files.bigSocialNetwork, true).toPromise();

      if (file) {
        newEvent = {
          ...newEvent,
          bigSocialNetworkFileId: file.id,
        };
      }
    }

    if (files?.invitation?.domFile) {
      const file = await this.#fileService.create(files.invitation, true).toPromise();
      if (file) {
        newEvent = {
          ...newEvent,
          invitationPdfFileId: file.id,
        };
      }
    }

    if (files?.badge?.domFile) {
      const file = await this.#fileService.create(files.badge, true).toPromise();
      if (file) {
        newEvent = {
          ...newEvent,
          recruiterBadgesFileId: file.id,
        };
      }
    }

    if (files?.candidateGuide?.domFile) {
      const file = await this.#fileService.create(files.candidateGuide, true).toPromise();
      if (file) {
        newEvent = {
          ...newEvent,
          candidateGuideFileId: file.id,
        };
      }
    }

    if (files?.summary?.domFile) {
      const file = await this.#fileService.create(files.summary, true).toPromise();
      if (file) {
        newEvent = {
          ...newEvent,
          summaryFileId: file.id,
        };
      }
    }

    if (files?.primaryImage?.domFile) {
      const file = await this.#fileService.create(files.primaryImage, true).toPromise();
      if (file) {
        newEvent = {
          ...newEvent,
          cvBookParameters: {
            ...newEvent?.cvBookParameters,
            imageFileId: file.id,
          },
        };
      }
    }

    if (newEvent.id) {
      await this.#opsEventService
        .updateEvent({ ...newEvent, timezone: newEvent.timezone || 'Europe/Paris' })
        .pipe(
          map((resp) => {
            ctx.patchState({ event: resp });

            this.updatePartialLoadingStatus(ctx, OpsEventBlocEnum.event, {
              updating: false,
              updated: true,
            });
          }),
          catchError((err) => {
            ctx.patchState({ loadingStatus: defaultState.loadingStatus });
            return this.toasterShow(err);
          })
        )
        .toPromise();
    } else {
      await this.#opsEventService
        .saveEvent({ ...newEvent, timezone: newEvent.timezone || 'Europe/Paris' })
        .pipe(
          switchMap((resp) => {
            ctx.patchState({ event: resp });

            this.updatePartialLoadingStatus(ctx, OpsEventBlocEnum.event, {
              updating: false,
              updated: true,
            });

            return this.#store.dispatch(new Navigate(['events', resp.id]));
          }),
          catchError((err) => {
            ctx.patchState({ loadingStatus: defaultState.loadingStatus });
            return this.toasterShow(err);
          })
        )
        .toPromise();
    }
  }
  /* eslint-enable sonarjs/cognitive-complexity */

  @Action(InitCreateEventState)
  initCreateEventState(ctx: StateContext<OpsEventDetailStateModel>): void {
    ctx.patchState({ loadingStatus: defaultState.loadingStatus });
  }

  @Action(ShowCreateEventInvitationFile)
  showCreateEventInvitationFile(ctx: StateContext<OpsEventDetailStateModel>): any {
    return this.#fileService
      .getDownloadUrl(ctx.getState().event.invitationPdfFileId)
      .pipe(switchMap((url) => of(window.open(url, '_blank'))));
  }

  @Action(ShowCreateEventBadgeFile)
  showCreateEventBadgeFile(ctx: StateContext<OpsEventDetailStateModel>): any {
    return this.#fileService
      .getDownloadUrl(ctx.getState().event.recruiterBadgesFileId)
      .pipe(switchMap((url) => of(window.open(url, '_blank'))));
  }

  @Action(ShowCreateEventCandidateGuideFile)
  showCreateEventCandidateGuideFile(ctx: StateContext<OpsEventDetailStateModel>): any {
    return this.#fileService
      .getDownloadUrl(ctx.getState().event.candidateGuideFileId)
      .pipe(switchMap((url) => of(window.open(url, '_blank'))));
  }

  @Action(ShowCreateEventSummaryFile)
  showCreateEventSummaryFile(ctx: StateContext<OpsEventDetailStateModel>): any {
    return this.#fileService
      .getDownloadUrl(ctx.getState().event.summaryFileId)
      .pipe(switchMap((url) => of(window.open(url, '_blank'))));
  }

  @Action(UpdateOpsEvent)
  updateOpsEvent(ctx: StateContext<OpsEventDetailStateModel>, { opsEvent }: UpdateOpsEvent): any {
    return ctx.patchState({ event: opsEvent });
  }

  toasterShow(error: HttpErrorResponse): Observable<HttpErrorResponse> {
    this.#toaster.show(
      new FeedbackToast({
        type: ToastType.DANGER,
        title: 'Erreur technique',
        message:
          error?.error?.message ??
          'Veuillez réessayer dans quelques instants, si le problème persiste veuillez contacter le support.',
      })
    );
    return throwError(error);
  }

  @Action(GenerateEventPlanning)
  generateEventPlanning(
    ctx: StateContext<OpsEventDetailStateModel>,
    { eventId }: GenerateEventPlanning
  ): Observable<void> {
    ctx.patchState({ event: { ...ctx.getState().event, isPlanningGenerated: true } });
    return this.#opsEventService.generateEventPlanning(eventId);
  }

  @Action(DeleteEventPlanning)
  deleteEventPlanning(ctx: StateContext<OpsEventDetailStateModel>, { eventId }: DeleteEventPlanning): Observable<void> {
    ctx.patchState({ event: { ...ctx.getState().event, isPlanningGenerated: false } });
    return this.#opsEventService.deleteEventPlanning(eventId);
  }

  private updatePartialLoadingStatus(
    ctx: StateContext<OpsEventDetailStateModel>,
    bloc: OpsEventBlocEnum,
    status: LoadingStatus
  ) {
    ctx.setState(
      patch<OpsEventDetailStateModel>({
        loadingStatus: patch({
          [bloc]: status,
        }),
      })
    );
  }
}
