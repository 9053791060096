export enum CandidateDetailTabsEnum {
  resume = 'resume',
  informations = 'informations',
  email = 'email',
  sms = 'sms',
  activities = 'activities',
  interviews = 'interviews',
  evaluations = 'evaluations',
}

export const candidateDetailTabItems: { id: CandidateDetailTabsEnum; label: string }[] = [
  {
    id: CandidateDetailTabsEnum.resume,
    label: 'CV',
  },
  {
    id: CandidateDetailTabsEnum.informations,
    label: 'Informations',
  },
  {
    id: CandidateDetailTabsEnum.email,
    label: 'Email',
  },
  {
    id: CandidateDetailTabsEnum.sms,
    label: 'SMS',
  },
  {
    id: CandidateDetailTabsEnum.activities,
    label: 'Activités',
  },
  {
    id: CandidateDetailTabsEnum.interviews,
    label: 'Entretiens',
  },
];
