import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  inject,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { EventDetailRoutingEnum } from '@features/events/event-detail/event-detail-routing.enum';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { CandidateDetailTabsEnum } from '@shared/models/enums/candidate-detail-tabs.enum';
import { Call } from '@shared/models/voip/call';
import { CallStatusEnum, CallStatusLabelEnum } from '@shared/models/voip/call-status.enum';
import { Calls } from '@shared/utils/voip/calls';
import { CheckCallIsStillActive, ClearCall, InitializeLineStatus, UpdateCall } from '@store/voip/voip.action';
import { VoipState } from '@store/voip/voip.state';
import { VoipService } from '@webservices/voip-api/voip.service';
import { FeedbackToast, ToastType } from '@widgets/toaster/toaster';
import { ToasterService } from '@widgets/toaster/toaster.service';
import { BugsnagService } from '@wizbii/angular-bugsnag';
import dayjs from 'dayjs';
import { BehaviorSubject, Observable, catchError, filter, map, of, switchMap, take, tap, timer } from 'rxjs';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'app-voip-toaster',
  templateUrl: './voip.component.html',
  styleUrls: ['./voip.component.scss'],
  imports: [CommonModule, MatIconModule, RouterModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VoipComponent implements OnInit, OnDestroy {
  @Input() voipLicenceId: string;

  @ViewChild('toaster') toaster: ElementRef;

  @Select(VoipState.firstCall) call$: Observable<Call>;

  timer$: Observable<string>;

  readonly FeaturesRoutingEnum = FeaturesRoutingEnum;
  readonly EventDetailRoutingEnum = EventDetailRoutingEnum;
  readonly CallStatusEnum = CallStatusEnum;
  readonly CallStatusLabelEnum = CallStatusLabelEnum;
  readonly CandidateDetailTabsEnum = CandidateDetailTabsEnum;

  readonly #store = inject(Store);
  readonly #renderer = inject(Renderer2);
  readonly #voipService = inject(VoipService);
  readonly #toaster = inject(ToasterService);
  readonly #bugsnagClient = inject(BugsnagService);

  pickedUp$ = new BehaviorSubject<boolean>(false);
  hungUp$ = new BehaviorSubject<boolean>(false);
  callHandle: string;

  ngOnInit(): void {
    this.#store.dispatch(new InitializeLineStatus(this.voipLicenceId));

    this.call$.pipe(untilDestroyed(this), filter(Calls.isCurrent)).subscribe({ next: () => this.setTimer() });
  }

  pickUp(): void {
    this.call$
      .pipe(
        take(1),
        filter((call) => !!call?.callHandle && !this.pickedUp$.value),
        tap(() => this.pickedUp$.next(true)),
        switchMap((call) => this.#voipService.pickUp(this.voipLicenceId, call.callHandle))
      )
      .subscribe({
        next: (call) => {
          this.setTimer();
          this.pickedUp$.next(false);
          this.#store.dispatch(new UpdateCall(call?.callHandle, CallStatusEnum.Current, call?.callStart));
        },
        error: () => {
          this.pickedUp$.next(false);
          this.#toaster.show(
            new FeedbackToast({
              type: ToastType.DANGER,
              title: 'Impossible de décrocher',
            })
          );
        },
      });
  }

  hangUp(): void {
    this.call$
      .pipe(
        take(1),
        tap((call) => {
          if (!call?.callHandle) {
            this.#bugsnagClient.sendError(
              `'[VoIP] Call hangup request will fail for licence ${this.voipLicenceId} because no callHandle is present'`
            );
          }
          this.callHandle = call?.callHandle;
        }),
        filter((call) => !!call?.callHandle && !this.hungUp$.value),
        tap(() => this.hungUp$.next(true)),
        switchMap((call) => this.#voipService.hangUp(this.voipLicenceId, call.callHandle)),
        catchError(() => {
          this.#store.dispatch(new CheckCallIsStillActive(this.callHandle));
          this.hungUp$.next(false);
          return of(this.callHandle);
        })
      )
      .subscribe({
        next: (callHandle) => {
          this.hungUp$.next(false);
          return this.#store.dispatch(new UpdateCall(callHandle, CallStatusEnum.Ended, null));
        },
      });
  }

  closeNotification(): void {
    if (this.toaster) this.#renderer.addClass(this.toaster.nativeElement, 'fadeOut');
    this.call$.pipe(take(1)).subscribe({ next: (call) => this.#store.dispatch(new ClearCall(call.callHandle)) });
  }

  ngOnDestroy(): void {
    this.pickedUp$.complete();
    this.hungUp$.complete();
  }

  private setTimer(): void {
    this.timer$ = timer(1000, 1000).pipe(
      switchMap(() => this.call$),
      filter((call) => !!call?.callStart),
      map((call) => dayjs(new Date()).diff(dayjs(call.callStart)) / 1000, 1000),
      map((x: number) => this.formatTimer(x))
    );
  }

  private formatTimer(seconds: number): string {
    const sec = Math.floor(seconds % 60);
    const decimalSec = sec < 10 ? '0' : '';
    const min = Math.floor(seconds / 60);
    const decimalMin = min < 10 ? '0' : '';
    return `${decimalMin}${min}:${decimalSec}${sec}`;
  }
}
