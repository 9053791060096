import { Utm } from '@shared/models/utm';

export class TrackedLink {
  id: string;
  description: string;
  url: string;
  linkId: string;
  utm: Utm;

  constructor(data: Record<string, any>) {
    this.id = data['id'];
    this.description = data['description'];
    this.url = data['url'];
    this.linkId = data['linkId'];
    this.utm = new Utm(data['utm'] || {});
  }
}
