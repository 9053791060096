import { MonthLabel, MonthShortenLabel } from '@shared/models/enums/dates';
import { twoDigitNumber } from '@shared/utils/formatters';

export function getNumberDay(dateEvent: Date): number {
  const date = new Date(dateEvent);
  return date.getDate();
}

export function getLabelMonth(dateEvent: Date, shorten = false): string {
  const month = new Date(dateEvent)?.getMonth();
  return shorten ? MonthShortenLabel[month] : MonthLabel[month];
}

export function getFullDate(date: Date): string {
  return `${getNumberDay(date)} ${getLabelMonth(date).toLowerCase()} ${date.getFullYear()} à ${twoDigitNumber(
    date.getHours()
  )}h${twoDigitNumber(date.getMinutes())}`;
}

export function getDateFromBirthdayString(birthday: string): Date {
  const dateSplit = birthday.split('/');
  return new Date(`${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`);
}

export function convertDate(date?: string | Date): Date | undefined {
  return date
    ? new Date(typeof date === 'string' ? date.replace(/\+([0-9]{2}):?([0-9]{2})/g, '+$1:$2') : date)
    : undefined;
}
