export class CandidateComment {
  id: string;
  author: string;
  dateCreated: Date;
  comment: string;

  constructor(data: Record<string, any>) {
    this.id = data['id'];
    this.author = data['author'];
    this.dateCreated = new Date(data['dateCreated']);
    this.comment = data['comment'];
  }
}
