import { StatusParticipationEnum } from '@shared/models/enums/status-participation.enum';

export class Transition {
  from: StatusParticipationEnum;
  tos: StatusParticipationEnum[];
  sendMailRequired: boolean;

  constructor(inputData?: Record<string, any>) {
    const data = inputData || {};

    this.from = data['from'];
    this.tos = data['tos'];
    this.sendMailRequired = data['sendingMailRequired'];
  }
}
