import { Injectable, inject } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { iif, insertItem, updateItem } from '@ngxs/store/operators';
import {
  CandidateNotificationResponseSubject,
  NotificationResponse,
} from '@shared/models/notifications/notification-response';
import {
  AddNotification,
  GetNotifications,
  HandleNotification,
  MarkAsRead,
} from '@store/notifications/notifications.action';
import { OpsEventNotificationService } from '@webservices/ops-event-api/ops-event-notification.service';
import { FeedbackToast, ToastType } from '@widgets/toaster/toaster';
import { ToasterService } from '@widgets/toaster/toaster.service';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const defaultState: NotificationResponse<CandidateNotificationResponseSubject>[] = [];

@State<NotificationResponse<CandidateNotificationResponseSubject>[]>({
  name: 'notifications',
  defaults: defaultState,
})
@Injectable()
export class NotificationsState {
  readonly #toaster = inject(ToasterService);
  readonly #opsEventNotificationService = inject(OpsEventNotificationService);

  @Action(GetNotifications)
  getNotifications(ctx: StateContext<NotificationResponse<CandidateNotificationResponseSubject>[]>): any {
    return this.#opsEventNotificationService.getNotifications().pipe(
      map((notifications) => {
        ctx.setState([...notifications]);
      }),
      catchError((err) => {
        this.#toaster.show(
          new FeedbackToast({
            type: ToastType.DANGER,
            title: 'Echec de la récupération des notifications',
            message: `Erreur ${err.status}; pour plus de détails, rapprochez-vous de l'équipe technique.`,
            duration: 3000,
          })
        );
        return throwError(err);
      })
    );
  }

  @Action(AddNotification)
  addNotification(
    ctx: StateContext<NotificationResponse<CandidateNotificationResponseSubject>[]>,
    action: AddNotification<CandidateNotificationResponseSubject>
  ): void {
    ctx.setState(insertItem(action.notification));
  }

  @Action(HandleNotification)
  handleNotification(
    ctx: StateContext<NotificationResponse<CandidateNotificationResponseSubject>[]>,
    action: HandleNotification<CandidateNotificationResponseSubject>
  ): void {
    ctx.setState(
      iif(
        ctx.getState().some((n) => n.id === action.notification.id),
        updateItem((n) => n.id === action.notification.id, action.notification),
        insertItem(action.notification)
      )
    );
  }

  @Action(MarkAsRead)
  markAsRead(
    ctx: StateContext<NotificationResponse<CandidateNotificationResponseSubject>[]>,
    action: MarkAsRead<CandidateNotificationResponseSubject>
  ): any {
    const updatedNotification: NotificationResponse<CandidateNotificationResponseSubject> = {
      ...action.notification,
      status: 'treated',
    };

    /* eslint-disable */
    return this.#opsEventNotificationService.setAsRead(action.notification.id).pipe(
      map(() => {
        ctx.setState(
          updateItem<NotificationResponse<CandidateNotificationResponseSubject>>(
            (n) => n.id === action.notification.id,
            updatedNotification
          )
        );
      })
    );
    /* eslint-enable */
  }
}
