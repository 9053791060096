import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { ClientType } from '@shared/models/client-type';
import { EventDashboardFilter } from '@shared/models/event-dashboard-filter';
import { EventType } from '@shared/models/event-type';
import { EventVisual } from '@shared/models/event-visual';
import { OpsEventApiStatusResponseInterface } from '@shared/models/notifications/interfaces/ops-event-api-status-response.interface';
import { OpsEvent } from '@shared/models/ops-event';
import type { OriginUtm } from '@shared/models/origin-utm';
import { Planning } from '@shared/models/planning';
import { RecruiterResponseToSearchRecruiter, SearchRecruiter } from '@shared/models/search-recruiter';
import { SseService } from '@shared/services/sse.service';
import { OpsRecruiter } from '@wizbii/utils/models';
import { Observable, concat } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OpsEventDetailService {
  readonly #http = inject(HttpClient);
  #sseService = inject(SseService);

  private readonly baseUrl = `${environment.api.opsEvent}/api/event`;
  private readonly baseUrlV2 = `${environment.api.opsEvent}/v2/event`;

  getPreviewDescription(eventId: string, description: string): Observable<string> {
    return this.#http
      .post<{ description: string }>(`${this.baseUrl}/${eventId}/preview-description`, {
        description,
      })
      .pipe(map((payload) => payload.description));
  }

  moveJobs(
    eventId: string,
    jobs: { jobId: string; sourceCompanyId: string; destinationCompanyId: string }[]
  ): Observable<void> {
    return this.#http.post<void>(`${this.baseUrlV2}/${eventId}/job/move`, jobs);
  }

  getEvent(eventId: string): Observable<OpsEvent> {
    return this.#http.get(`${this.baseUrlV2}/${eventId}`).pipe(map((item) => new OpsEvent(item)));
  }

  closeEvent(eventId: string): Observable<void> {
    return this.#http.post<void>(`${this.baseUrl}/${eventId}/close`, {});
  }

  generateEventPlanning(eventId: string): Observable<void> {
    return this.#http.post<void>(`${environment.api.opsEvent}/v2/planning/create/${eventId}`, {});
  }

  deleteEventPlanning(eventId: string): Observable<void> {
    return this.#http.delete<void>(`${environment.api.opsEvent}/v2/planning/${eventId}`, {});
  }

  getSourcePlatform(): Observable<OriginUtm> {
    return this.#http.get<OriginUtm>(`${environment.api.opsEvent}/v2/source-platform`);
  }

  saveEvent(event: OpsEvent): Observable<OpsEvent> {
    return this.#http.post(`${this.baseUrlV2}`, event).pipe(map((eventResponse) => new OpsEvent(eventResponse)));
  }

  updateEvent(event: OpsEvent): Observable<OpsEvent> {
    return this.#http
      .put(`${this.baseUrlV2}/${event.id}`, event)
      .pipe(map((eventResponse) => new OpsEvent(eventResponse)));
  }

  deleteEvent(eventId: string): Observable<OpsEventApiStatusResponseInterface> {
    return this.#http.delete<OpsEventApiStatusResponseInterface>(`${this.baseUrlV2}/${eventId}`);
  }

  getTimezone(): Observable<string[]> {
    return this.#http.get<string[]>(`${this.baseUrl}/accepted-timezones`);
  }

  addCompanyRecruiter(recruiterParams: OpsRecruiter, companyId: string): Observable<SearchRecruiter> {
    return this.#http
      .post(`${environment.api.opsEvent}/v2/recruiter`, {
        firstName: recruiterParams.firstName,
        lastName: recruiterParams.lastName,
        mail: recruiterParams.mail,
        phone: recruiterParams.phone,
        companyId: companyId,
        position: recruiterParams.position,
        status: recruiterParams.status,
      })
      .pipe(map((recruiterResponse: any) => RecruiterResponseToSearchRecruiter(recruiterResponse, companyId)));
  }

  getCompanyPlanning(eventId: string, companyId: string): Observable<Planning> {
    return concat(
      this.requestCompanyPlanningFromApi(eventId, companyId),
      this.subscribeToCompanyPlanningMercureUpdates(eventId, companyId)
    );
  }

  private requestCompanyPlanningFromApi(eventId: string, companyId: string): Observable<Planning> {
    return this.#http.get<Planning>(`${environment.api.opsEvent}/v2/planning/${eventId}/company/${companyId}`);
  }

  private subscribeToCompanyPlanningMercureUpdates(eventId: string, companyId: string): Observable<Planning> {
    return this.#sseService
      .getServerSentEvent(
        `${environment.api.mercure}?topic=http://sourcii/event/${eventId}/planning/company/${companyId}`
      )
      .pipe(map((payload) => JSON.parse(payload.data)));
  }

  requestDownloadCvBook(eventId: string, companyId: string): Observable<any> {
    return this.#http.get(`${environment.api.opsEvent}/v2/cvbook-static/event/${eventId}/company/${companyId}`);
  }

  downloadExtractFile(eventId: string): Observable<{
    blob: Blob;
    filename: string;
  }> {
    return this.#http
      .get(`${this.baseUrl}/${eventId}/extract`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(map(this.buildBlob));
  }

  downloadExtractQuestion(eventId: string): Observable<{
    blob: Blob;
    filename: string;
  }> {
    return this.#http
      .get(`${this.baseUrl}/${eventId}/extract/question`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(map(this.buildBlob));
  }

  doDownloadConsentExtract(eventId: string): Observable<{
    blob: Blob;
    filename: string;
  }> {
    return this.#http
      .get(`${this.baseUrlV2}/${eventId}/extract-consent-list`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(map(this.buildBlob));
  }

  doDownloadJobList(eventId: string): Observable<{
    blob: Blob;
    filename: string;
  }> {
    return this.#http
      .get(`${this.baseUrlV2}/${eventId}/extract-event-jobs`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(map(this.buildBlob));
  }

  requestImageConsentDownload(eventId: string): Observable<HttpResponse<void>> {
    return this.#http.get<HttpResponse<void>>(`${this.baseUrlV2}/${eventId}/extract-consent`);
  }

  private buildBlob(res: any): {
    blob: Blob;
    filename: string;
  } {
    const filename: string = res.headers.get('content-disposition').split(';')[1].trim().split('=')[1];
    return {
      blob: new Blob([res.body], { type: res.headers.get('Content-Type') }),
      filename: filename.substring(1).substring(0, filename.length - 2),
    };
  }

  getEventTypes(): Observable<EventType[]> {
    return this.#http
      .get<Record<string, unknown>[]>(`${environment.api.opsEvent}/v2/event-types`)
      .pipe(map((eventTypes) => eventTypes.map((eventType) => new EventType(eventType))));
  }

  createEventType(eventType: EventType): Observable<EventType> {
    return this.#http
      .post(`${environment.api.opsEvent}/v2/event-types`, eventType)
      .pipe(map((newEventType) => new EventType(newEventType)));
  }

  getClientTypes(): Observable<ClientType[]> {
    return this.#http.get<ClientType[]>(`${environment.api.opsEvent}/v2/client-types`);
  }

  getDistinctUtm(eventId: string): Observable<EventDashboardFilter[]> {
    return this.#http
      .get<{ utms: Record<string, unknown>[] }>(`${this.baseUrl}/${eventId}/distinct-utm`)
      .pipe(map((payload) => payload.utms.map((filters) => new EventDashboardFilter(filters))));
  }

  getVisualsForEvent(eventId: string): Observable<EventVisual[]> {
    return this.#http.get<EventVisual[]>(`${environment.api.opsEvent}/v2/visual-material/event/${eventId}`);
  }

  requestVisual(event: OpsEvent, visualType: string): Observable<HttpResponse<any>> {
    return this.#http.get<HttpResponse<any>>(
      `${environment.api.opsEvent}/v2/visual-material/event/${event.id}/download/${visualType}`
    );
  }

  updateTeamMembers(
    eventId: string,
    teamMembers: string[],
    eventOwnerId: string,
    eventManagers: string[]
  ): Observable<OpsEvent> {
    return this.#http
      .put<Record<string, unknown>>(`${this.baseUrlV2}/${eventId}/members`, {
        teamMembers,
        eventOwnerId,
        eventManagers,
      })
      .pipe(map((obj) => new OpsEvent(obj)));
  }
}
