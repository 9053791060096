import { Inject, Injectable, inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { UserRoleEnum } from '@shared/models/enums/user-roles-label.enum';
import { JwtTokens, deserializeJwt } from '@wizbii-utils/angular/jwt';
import { JWT_STATE_CONFIG, JwtStateConfigInterface } from '@wizbii-utils/angular/stores';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AdminGuard {
  readonly #router = inject(Router);
  readonly #cookieService = inject(CookieService);

  constructor(@Inject(JWT_STATE_CONFIG) private readonly jwtStateConfig: JwtStateConfigInterface) {}

  private readTokens(): JwtTokens | null {
    const rawTokens = JSON.parse(this.#cookieService.get(this.jwtStateConfig.jwtCookieName) || 'null');
    return rawTokens ? rawTokens : null;
  }

  canActivate(): boolean | UrlTree {
    const tokens = this.readTokens();
    const roles = tokens?.token ? deserializeJwt(tokens.token)['roles'] : null;
    const canAccess = roles.some(
      (role) => role === UserRoleEnum.PROJECT_MANAGER || role === UserRoleEnum.SOURCING_MANAGER
    );

    return canAccess ? true : this.#router.parseUrl('/');
  }
}
