import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { PopupComponent } from '@widgets/popup/popup/popup.component';

@Directive({
  selector: '[appPopupItem]',
})
export class PopupItemDirective {
  @HostBinding('attr.role') @Input() popupRole? = 'menu';

  constructor(private readonly parent: PopupComponent) {}

  @HostBinding('id')
  get id(): string {
    return `${this.parent.id}__menu`;
  }

  @HostBinding('attr.aria-labelledby')
  get triggerId(): string {
    return `${this.parent.id}__trigger`;
  }

  @HostBinding('hidden')
  get hidden(): boolean {
    return !this.parent.opened;
  }

  @HostListener('click')
  onClick(): void {
    if (this.parent.autoClose && this.parent.opened) {
      this.parent.close();
    }
  }
}
