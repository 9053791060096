export const MonthLabel = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];

export const MonthShortenLabel = [
  'Jan.',
  'Fév.',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Sept.',
  'Oct.',
  'Nov.',
  'Déc.',
];
