import { isPossibleNumber } from 'libphonenumber-js';
import { parsePhoneNumber } from 'libphonenumber-js/min';

/* eslint-disable no-prototype-builtins */
export function getObjectivePercentage(applied: number, required: number): string {
  if (required === 0) {
    return '0%';
  }
  return applied < required ? `${Math.round((applied / required) * 10000) / 100}%` : '100%';
}

export function extractInfoFromJwt(jwt: string, key: string): any {
  const jwtBody = JSON.parse(atob(jwt.split('.')[1]));
  if (!jwtBody.hasOwnProperty(key)) {
    throw new Error('jwt token has incorrect format');
  }
  return jwtBody[key];
}

export function intToHex(colorInt: number): string {
  let colorStr = colorInt.toString(16);
  const diff = 6 - colorStr.length;

  for (let i = 0; i < diff; i++) {
    colorStr = `0${colorStr}`;
  }

  colorStr = `#${colorStr}`;

  return colorStr;
}

export function slugify(str: string): string {
  return str
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s/g, '_');
}

export function twoDigitNumber(nb: number): string {
  return nb < 10 ? `0${nb}` : `${nb}`;
}

export function ifPhoneRemoveSpacesElseTrim(query: string): string {
  if (isPossibleNumber(query, 'FR')) {
    const phoneNumber = parsePhoneNumber(query, 'FR');
    if (phoneNumber) {
      return phoneNumber.number;
    }
  }

  return query?.trim();
}
