import { Injectable, inject } from '@angular/core';
import { UrlTree } from '@angular/router';
import { environment } from '@environment';
import { Store } from '@ngxs/store';
import { JwtState } from '@wizbii-utils/angular/stores';
import { BugsnagService } from '@wizbii/angular-bugsnag';
import { Params, buildUrl } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MustBeConnectedGuard {
  readonly #store = inject(Store);
  readonly #bugsnagClient = inject(BugsnagService);

  canActivate(): Observable<boolean | UrlTree> {
    return this.#store.selectOnce(JwtState).pipe(
      map((tokens) => !!tokens),
      map((isLogged) => {
        if (!isLogged) {
          this.#bugsnagClient.sendError('Unauthorized route', 'info');

          const params: Params = {
            redirect_url: environment.urls.ssoRedirectUrl,
            appId: environment.applicationId,
          };

          window.open(buildUrl(environment.api.sso, params), '_self');

          return false;
        }
        return true;
      })
    );
  }
}
