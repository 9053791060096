import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ErrorHandler,
  HostBinding,
  HostListener,
  OnInit,
  PLATFORM_ID,
  inject,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { VoipSseService } from '@core/services/voip-sse/voip-sse.service';
import { environment } from '@environment';
import { UntilDestroy } from '@ngneat/until-destroy';
import { RouterState } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { SseService } from '@shared/services/sse.service';
import { UserState } from '@store/user/user.state';
import { JwtState } from '@wizbii-utils/angular/stores';
import { JwtStateModel } from '@wizbii-utils/angular/stores/src/jwt/jwt.state';
import { WebVitalsOptions, WebVitalsParams, sendWebVitals } from '@wizbii/utils';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreComponent implements OnInit {
  readonly #store = inject(Store);
  readonly #sseService = inject(SseService);
  readonly #titleService = inject(Title);
  readonly #voipSseService = inject(VoipSseService);
  readonly #errorHandler = inject(ErrorHandler) as BugsnagErrorHandler;
  readonly #platformId = inject(PLATFORM_ID);

  /**
   * Use class `hover-on` in CSS as follows:
   * `:host-context(.hover-on) .link:hover { ... }`
   */
  readonly platform = environment.platform;

  readonly jwtState$: Observable<JwtStateModel> = this.#store.select(JwtState);
  readonly voipLicenceId$: Observable<string> = this.#store.select(UserState.voipLicenceId);

  @HostBinding('class.hover-on') hoverEnabled = true;

  @HostBinding('class.hover-off')
  get hoverDisabled(): boolean {
    return !this.hoverEnabled;
  }

  /**
   * Class `focus-off` disables all outlines automatically.
   */
  @HostBinding('class.focus-off') focusOutlineDisabled = false;

  constructor() {
    // Provide user data to Bugsnag
    this.#store.select(UserState.userProfile).subscribe({
      next: (userProfile) => {
        if (userProfile) {
          this.#errorHandler.bugsnagClient.setUser(
            userProfile.id,
            userProfile.username,
            `${userProfile.firstName} ${userProfile.lastName}`
          );
        }
      },
    });
  }

  ngOnInit(): void {
    if (environment.platform !== 'prod') {
      this.#titleService.setTitle(`${environment.platform.toUpperCase()} - ${this.#titleService.getTitle()}`);
    }

    this.#sseService.getServerSentEvent(`${environment.api.mercure}?topic=http://sourcii/version`).subscribe(() => {
      alert(
        'Il y a une nouvelle version de Sourcii, pensez à sauvegarder vos modifications et à recharger la page dès que possible :)'
      );
    });

    this.#voipSseService.subscribeToVoipEvents();

    const routerState = this.#store.selectSnapshot(RouterState)?.state;
    if (routerState?.url) {
      const params: WebVitalsParams = {
        params: routerState.params,
        path: routerState.url,
        applicationId: environment.applicationId,
        envFqdn: environment.domain.wizbii,
      };
      const options: WebVitalsOptions = {
        dev: environment.platform === 'local',
        debug: environment.platform === 'local',
        browser: isPlatformBrowser(this.#platformId),
      };
      sendWebVitals(params, options);
    }
  }

  /**
   * Enable hover if "mouse" pointer event is detected; disable it otherwise.
   * https://developer.mozilla.org/en-US/docs/Web/Events/pointerenter
   */
  @HostListener('pointerenter', ['$event'])
  onPointerEnter(event: any): void {
    const evt: PointerEvent = event; // https://github.com/kulshekhar/ts-jest/issues/1035
    this.hoverEnabled = evt.pointerType === 'mouse';
  }

  /**
   * Disable hover on `touchstart` to cover browsers that do not support pointer events.
   * https://caniuse.com/#feat=pointer
   */
  @HostListener('touchstart')
  onTouchStart(): void {
    this.hoverEnabled = false;
  }

  @HostListener('mousedown')
  onMouseDown(): void {
    this.focusOutlineDisabled = true;
  }

  @HostListener('keydown.Tab')
  onTabKeyDown(): void {
    this.focusOutlineDisabled = false;
  }
}
