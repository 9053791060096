import { StatusParticipationEnum } from '@shared/models/enums/status-participation.enum';
import { Utm } from '@shared/models/utm';

export class EventDashboardFilter {
  utm: Utm;
  statusParticipation: StatusParticipationEnum;

  constructor(data: Record<string, any>) {
    this.utm = data['utm'];
    this.statusParticipation = data['statusParticipation'];
  }
}
