export class User {
  id: string;
  firstName: string;
  lastName: string;
  phone: string;
  roles: string[];
  username: string;
  title: string;
  licenceId: string;
  voipNumber?: string;

  constructor(data: Record<string, any>) {
    this.id = data['id'];
    this.firstName = data['firstName'];
    this.lastName = data['lastName'];
    this.phone = data['phone'];
    this.roles = data['roles'];
    this.username = data['username'];
    this.title = data['title'];
    this.licenceId = data['licenceId'];
    this.voipNumber = data['voipNumber'];
  }

  toJson(): any {
    return {
      ...this,
      _id: this.id,
    };
  }
}

export function toUser(data: Record<string, any>): User {
  return new User({
    ...data,
    id: data['_id'] || data['id'],
  });
}
