export class MailAttachment {
  fileId: string;
  fileName: string;
  contentType: string;
  dateCreated: Date;

  constructor(data: Record<string, any>) {
    this.fileId = data['fileId'];
    this.fileName = data['filename'];
    this.contentType = data['contentType'];
    this.dateCreated = new Date(data['dateCreated']);
  }
}
