<!-- eslint-disable @angular-eslint/template/no-call-expression -->
@if (notifications$ | async; as notifications) {
  <app-popup class="notification" [autoClose]="mustClosePopupOnClick()" (openedChange)="openChangePopup($event)">
    <button class="btn-clean notification__bell" type="button" appPopupTrigger>
      <mat-icon svgIcon="bell" />
      @if (notifications?.length > 0) {
        @if (notificationCount$ | async; as notificationCount) {
          @if (notificationCount > 0) {
            <span class="notification__bell__counter" [@pop]>
              {{ notificationCount }}
            </span>
          }
        }
      }
    </button>

    <div class="notification__popup-menu" appPopupItem>
      <span class="notification__popup-menu__title">
        <strong>{{ notifications?.length }}</strong>

        <ng-container [ngPlural]="notifications?.length || 0">
          <ng-template ngPluralCase="=0"> notification</ng-template>
          <ng-template ngPluralCase="=1"> notification</ng-template>
          <ng-template ngPluralCase="other"> notifications</ng-template>
        </ng-container>
      </span>

      <ng-scrollbar class="notification__popup-menu__wrapper">
        @if (notifications?.length) {
          <ul class="ul-clean notification-list">
            @for (notification of notifications; track notification.id) {
              <li class="notification-list__item">
                @switch (notification.subject.type) {
                  @case (NotificationTypeEnum.sms) {
                    <app-notification
                      [notification]="notification"
                      [userId]="userId"
                      [routerLink]="[
                        '/',
                        FeaturesRoutingEnum.Events,
                        notification.event.id,
                        EventDetailRoutingEnum.candidate,
                        notification.subject.candidate.id,
                      ]"
                      [queryParams]="{
                        tab: CandidateDetailTabsEnum.sms,
                      }"
                      (click)="markAsRead(notification)"
                      queryParamsHandling="merge"
                    >
                      <ng-container text
                        >SMS de {{ notification.subject.candidate.firstName }}
                        {{ notification.subject.candidate.lastName }}</ng-container
                      >
                      <mat-icon
                        class="notification__icon notification__icon--green"
                        [class.notification__icon--treated]="notification.status === 'treated'"
                        mainIcon
                        svgIcon="notif-sms"
                      />
                      <mat-icon treatIcon svgIcon="arrow-right" />
                    </app-notification>
                  }

                  @case (NotificationTypeEnum.eventParticipationConfirmation) {
                    <app-notification
                      [notification]="notification"
                      [userId]="userId"
                      [routerLink]="[
                        '/',
                        FeaturesRoutingEnum.Events,
                        notification.event.id,
                        EventDetailRoutingEnum.candidate,
                        notification.subject.candidate.id,
                      ]"
                      [queryParams]="{
                        tab: CandidateDetailTabsEnum.informations,
                      }"
                      (click)="markAsRead(notification)"
                      queryParamsHandling="merge"
                    >
                      <ng-container text>
                        {{ notification.subject.candidate.firstName }} {{ notification.subject.candidate.lastName }} a
                        {{ notification.subject.hasConfirmed ? 'confirmé' : 'refusé' }}
                      </ng-container>
                      <mat-icon
                        class="notification__icon"
                        [svgIcon]="notification.subject.hasConfirmed ? 'notif-accepted' : 'notif-refused'"
                        [class.notification__icon--treated]="notification.status === 'treated'"
                        [class.notification__icon--green]="notification.subject.hasConfirmed"
                        [class.notification__icon--red]="!notification.subject.hasConfirmed"
                        mainIcon
                      />
                      <mat-icon treatIcon svgIcon="check" />
                    </app-notification>
                  }

                  @case (NotificationTypeEnum.downloadReady) {
                    <app-notification
                      [notification]="notification"
                      [userId]="userId"
                      (click)="downloadFileAndSetAsTreated(notification)"
                    >
                      <ng-container text
                        >Votre fichier lié à l&apos;évènement {{ notification.event.id }} est prêt : cliquez ici pour le
                        télécharger</ng-container
                      >
                      <mat-icon
                        class="notification__icon notification__icon--green"
                        [class.notification__icon--treated]="notification.status === 'treated'"
                        mainIcon
                        svgIcon="notif-accepted"
                      />
                      <mat-icon treatIcon svgIcon="arrow-right" />
                    </app-notification>
                  }

                  @case (NotificationTypeEnum.jobOfferPendingValidation) {
                    <app-notification
                      [notification]="notification"
                      [userId]="userId"
                      (click)="openJobOfferAndSetAsTreated(notification)"
                    >
                      <ng-container text
                        >Une entreprise a envoyé une nouvelle offre d'emploi. Cliquez ici pour y accéder</ng-container
                      >
                      <mat-icon
                        class="notification__icon notification__icon--green"
                        [class.notification__icon--treated]="notification.status === 'treated'"
                        mainIcon
                        svgIcon="notif-accepted"
                      />
                      <mat-icon treatIcon svgIcon="arrow-right" />
                    </app-notification>
                  }
                  @case (NotificationTypeEnum.jobOfferReviewed) {
                    <app-notification
                      [notification]="notification"
                      [userId]="userId"
                      (click)="openJobOfferAndSetAsTreated(notification)"
                    >
                      <ng-container text
                        >Une nouvelle offre d'emploi a été
                        {{ notification.subject.status === JobReviewedEnum.validated ? 'validée' : 'refusée' }}. Cliquez
                        ici pour y accéder</ng-container
                      >
                      <mat-icon
                        class="notification__icon notification__icon--green"
                        [svgIcon]="
                          notification.subject.status === JobReviewedEnum.validated ? 'notif-accepted' : 'notif-refused'
                        "
                        [class.notification__icon--treated]="notification.status === 'treated'"
                        [class.notification__icon--green]="notification.subject.status === JobReviewedEnum.validated"
                        [class.notification__icon--red]="notification.subject.status === JobReviewedEnum.rejected"
                        mainIcon
                      />
                      <mat-icon treatIcon svgIcon="arrow-right" />
                    </app-notification>
                  }
                }
              </li>
            }
          </ul>
        }
      </ng-scrollbar>
    </div>
  </app-popup>
}
