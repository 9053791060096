import { Line } from '@shared/models/voip/line';
import { LineStatusEnum } from '@shared/models/voip/line-status.enum';

export class Lines {
  static isBusy(line: Line): boolean {
    return line?.state === LineStatusEnum.Busy;
  }

  static isFree(line: Line): boolean {
    return line?.state === LineStatusEnum.Free;
  }
}
