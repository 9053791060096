import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { AlgoliaClientProvider } from '@wizbii-utils/angular/algolia';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SearchCandidateListService {
  readonly #algoliaClientProvider = inject(AlgoliaClientProvider);

  getFilteredCandidatesIdsForEvent(query: string, eventId: string): Observable<any[]> {
    return this.getIndex().pipe(
      switchMap((index) =>
        index.search(query, {
          hitsPerPage: 40,
          facetFilters: [`event_id:${eventId}`],
        })
      ),
      map((response) => (response['hits'] || []).map((item) => item.candidate_id))
    );
  }

  getFilteredCandidatesParticipationsIds(query: string): Observable<any[]> {
    return this.getIndex().pipe(
      switchMap((index) =>
        index.search(query, {
          hitsPerPage: 40,
          facetFilters: [],
        })
      ),
      map((response) =>
        (response['hits'] || []).map((item) => {
          return { candidateId: item.candidate_id, eventId: item.event_id };
        })
      )
    );
  }

  private getIndex(): Observable<any> {
    return this.#algoliaClientProvider
      .getClient('candidates')
      .pipe(map((it) => it.initIndex(environment.algolia.index.candidates)));
  }
}
