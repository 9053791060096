import { CvBookParameters } from '@shared/models/cv-book-parameters';
import { EventStatusEnum } from '@shared/models/enums/event-status.enum';
import { Question } from '@shared/models/question';
import { MessageTypeEnum, TextTemplate } from '@shared/models/sms-templates/text-template';
import { TrackedLink } from '@shared/models/tracked-link';
import { City } from '@wizbii/utils/models';
import dayjs from 'dayjs';

export class OpsEvent {
  id?: string;
  name: string;
  metaTitle: string;
  metaDescription: string;
  wizCompanyId: string;
  description: string;
  areas: City[];
  date: string;
  scheduleStart: string;
  scheduleEnd: string;
  campaignId: string;
  type: string;
  clientId?: string;
  location: City;
  venue?: string;
  address: string;
  thumbnailFileId: string;
  bannerFileId: string;
  smallSocialNetworkFileId: string | undefined;
  bigSocialNetworkFileId: string;
  summaryFileId: string;
  invitationPdfFileId: string;
  recruiterBadgesFileId: string;
  candidateGuideFileId: string;
  templateInvitation: TextTemplate;
  templateReject: TextTemplate;
  textMessageTemplates: TextTemplate[];
  mailMessageTemplates: TextTemplate[];
  confirmedCandidates = 0;
  requiredCandidates = 0;
  questions: Question[];
  cvBookParameters: CvBookParameters;
  trackedLinks: TrackedLink[];
  teamMembers: string[];
  status: EventStatusEnum;
  publicName?: string;
  salonUrl?: string;
  dateSourcingEnd: string;
  eventOwnerId?: string;
  eventManagers?: string[];
  timezone?: string;
  isPlanningGenerated: boolean;
  trackedLinksDestination: string | null;

  // eslint-disable-next-line sonarjs/cognitive-complexity
  constructor(data: Record<string, any>) {
    this.id = data['id'];
    this.name = data['name'];
    this.metaTitle = data['metaTitle'];
    this.metaDescription = data['metaDescription'];
    this.description = data['description'];
    this.wizCompanyId = data['wizCompanyId'];
    this.date = data['date'] || dayjs().format();
    this.scheduleStart = data['scheduleStart'] || '';
    this.scheduleEnd = data['scheduleEnd'] || '';
    this.campaignId = data['campaignId'];
    this.type = data['type'];
    this.clientId = data['clientId'];
    this.status = data['status'];
    this.location = data['location'] ? new City(data['location']) : null;
    this.address = data['address'];
    this.venue = data['venue'];
    this.thumbnailFileId = data['thumbnailFileId'];
    this.bannerFileId = data['bannerFileId'];
    this.smallSocialNetworkFileId = data['smallSocialNetworkFileId'];
    this.bigSocialNetworkFileId = data['bigSocialNetworkFileId'];
    this.summaryFileId = data['summaryFileId'];
    this.invitationPdfFileId = data['invitationPdfFileId'];
    this.recruiterBadgesFileId = data['recruiterBadgesFileId'];
    this.candidateGuideFileId = data['candidateGuideFileId'];
    this.textMessageTemplates = data['textMessageTemplates'] || [];
    this.mailMessageTemplates = data['mailMessageTemplates'] || [];

    const foundTemplateInvitation = this.mailMessageTemplates.find(
      (template) => template.messageType === MessageTypeEnum.invitation
    );

    this.templateInvitation = { ...foundTemplateInvitation };

    if (data['templateInvitation']) {
      this.templateInvitation.content = data['templateInvitation']['content'];
      this.templateInvitation.subject = data['templateInvitation']['subject'];
    }

    const foundTemplateRefused = this.mailMessageTemplates.find(
      (template) => template.messageType === MessageTypeEnum.refused
    );

    this.templateReject = { ...foundTemplateRefused };

    if (data['templateReject']) {
      this.templateReject.content = data['templateReject']['content'];
      this.templateReject.subject = data['templateReject']['subject'];
    }

    this.confirmedCandidates = data['confirmedCandidates'] || 0;
    this.requiredCandidates = data['requiredCandidates'] || 0;
    this.questions = data['questions'] || [];
    this.cvBookParameters = {
      ...new CvBookParameters(data['cvBookParameters'] || {}),
    };
    this.trackedLinks = (data['trackedLinks'] || []).map((link: any) => new TrackedLink(link));
    this.areas = (data['areas'] || []).map((area: any) => new City(area));
    this.teamMembers = data['teamMembers'] || [];
    this.publicName = data['publicName'] || undefined;
    this.eventOwnerId = data['eventOwnerId'] || undefined;
    this.eventManagers = data['eventManagers'] || [];
    this.timezone = data['timezone'] || undefined;
    this.salonUrl = data['salonUrl'] || undefined;
    this.dateSourcingEnd = data['dateSourcingEnd'] ? data['dateSourcingEnd'] : null;
    this.isPlanningGenerated = data['isPlanningGenerated'];
    this.trackedLinksDestination = data['trackedLinksDestination'] || null;
  }
}
