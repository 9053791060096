import { convertDate } from '@shared/utils/date';

export enum NotificationTypeEnum {
  sms = 'sms',
  eventParticipationConfirmation = 'eventParticipationConfirmation',
  downloadReady = 'downloadReadyNotification',
  jobOfferPendingValidation = 'jobOfferPendingValidation',
  jobOfferReviewed = 'jobOfferReviewed',
}

export interface NotificationResponseSubject {
  type: NotificationTypeEnum;
}

export interface NotificationResponse<T extends NotificationResponseSubject> {
  id: string;
  recipients: string[];
  event: { id: string; name: string };
  dateCreated: Date;
  subject: T;
  status: string;
  treatedBy: string;
  treatedAt: Date;
  readBy: string[];
}

export function toNotificationResponse(data: any = {}): NotificationResponse<NotificationResponseSubject> {
  return {
    ...data,
    dateCreated: convertDate(data.dateCreated),
  };
}

export interface CandidateNotificationResponseSubject extends NotificationResponseSubject {
  candidate: {
    id: string;
    firstName: string;
    lastName: string;
  };
  hasConfirmed: boolean;
}

export enum JobReviewedEnum {
  'validated' = 'validated',
  'rejected' = 'rejected',
}

export interface DownloadReadyNotificationResponseSubject extends NotificationResponseSubject {
  fileId: string;
}

export interface NewJobOfferNotificationResponseSubject extends NotificationResponseSubject {
  eventId: string;
  companyId: string;
}

/* eslint-disable sonarjs/no-identical-functions */
export function toCandidateNotificationResponse(
  data: any = {}
): NotificationResponse<CandidateNotificationResponseSubject> {
  return {
    ...data,
    dateCreated: convertDate(data.dateCreated),
  };
}
