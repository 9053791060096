import { LineStatusEnum } from '@shared/models/voip/line-status.enum';

export enum CallStatusEnum {
  'In' = 'in',
  'Out' = 'out',
  'Current' = 'current',
  'Ended' = 'ended',
}

export enum CallStatusLabelEnum {
  'in' = 'entrant',
  'out' = 'sortant',
  'current' = 'en cours',
  'ended' = 'terminé',
}

export enum LineStatusToCallStatusMappingEnum {
  'ringing' = CallStatusEnum.In,
  'calling' = CallStatusEnum.Out,
  'busy' = CallStatusEnum.Current,
}

export enum CallStatusToLineStatusMappingEnum {
  'in' = LineStatusEnum.Ringing,
  'out' = LineStatusEnum.Calling,
  'current' = LineStatusEnum.Busy,
}

export enum CallStatusScoringEnum {
  Current = 1,
  In,
  Out,
  Ended,
}
