import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  HEADER_SKIP_SSE_REFRESH_TOKEN,
  HEADER_SKIP_SSE_TOKEN,
  SSE_SERVICE_TOKEN,
} from '@shared/services/sse-interceptor/sse-injection.config';
import { SseServiceInterface } from '@shared/services/sse-interceptor/sse.service.interface';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';

@Injectable()
export class SseInterceptor implements HttpInterceptor {
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  jwtHelperService: JwtHelperService;

  constructor(@Inject(SSE_SERVICE_TOKEN) private readonly sseService: SseServiceInterface) {
    this.jwtHelperService = new JwtHelperService();
  }

  addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    const hasXAuthTypeJwt = request.headers.get('x-auth-type') === 'jwt';
    const otherheaders = hasXAuthTypeJwt
      ? {
          'x-auth-token': token,
          'x-auth-type': 'jwt',
        }
      : {};
    return request.clone({
      setHeaders: { Authorization: `Bearer ${token}`, ...otherheaders },
    });
  }

  /* eslint-disable sonarjs/cognitive-complexity */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers && (request.headers.has(HEADER_SKIP_SSE_TOKEN) || !request.url.includes('https://mercure.'))) {
      const headers = request.headers.delete(HEADER_SKIP_SSE_TOKEN);
      return next.handle(request.clone({ headers }));
    }

    return this.sseService.getToken().pipe(
      take(1),
      switchMap((token: string) => {
        if (
          token &&
          !this.sseService.blacklistRoutes.some((apiUrl) => {
            return apiUrl instanceof RegExp ? apiUrl.test(request.url) : request.url.indexOf(apiUrl) !== -1;
          })
        ) {
          if (this.jwtHelperService.isTokenExpired(token) && !request.headers.has(HEADER_SKIP_SSE_REFRESH_TOKEN)) {
            return this.handle401Error(request, next);
          }

          return next.handle(this.addToken(request, token)).pipe(
            catchError((error) => {
              if (error instanceof HttpErrorResponse && error.status === 401) {
                return this.handle401Error(request, next);
              }
              return throwError(error);
            })
          );
        }

        return next.handle(request);
      })
    );
  }

  handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      return this.sseService.refreshToken().pipe(
        switchMap((newToken: string) => {
          if (newToken) {
            this.tokenSubject.next(newToken);
            this.sseService.saveLocalToken(newToken);
            return next.handle(this.addToken(request, newToken));
          }

          // If we don't get a new token, we are in trouble so logout.
          return this.logoutUser();
        }),
        catchError((error) => {
          const status = error.status;
          // If there is an exception calling 'refreshToken', bad news so logout.
          if (status >= 400 && status < 500) {
            return this.logoutUser(error);
          }

          return throwError(error);
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );
    }

    return this.tokenSubject.pipe(
      filter((tokens) => tokens !== null),
      take(1),
      switchMap((token) => {
        return next.handle(this.addToken(request, token));
      })
    );
  }

  logoutUser(error?: any): any {
    this.sseService.logout();
    return throwError(error ? error : 'LOGOUT');
  }
}
