export class Utm {
  source: string;
  medium: string;
  campaign: string;
  term: string;
  content: string;

  constructor(data: Record<string, any>) {
    this.source = data['source'];
    this.medium = data['medium'];
    this.campaign = data['campaign'];
    this.term = data['term'];
    this.content = data['content'];
  }
}
