import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '@environment';
import { CandidatesRoutingEnum } from '@features/candidates/candidates-routing.enum';
import { CompaniesRoutingEnum } from '@features/companies/companies-routing.enum';
import { ErrorComponent } from '@features/error/error.component';
import { EventDetailRoutingEnum } from '@features/events/event-detail/event-detail-routing.enum';
import { EventsRoutingEnum } from '@features/events/events-routing.enum';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { LayoutComponent } from '@features/layout/layout.component';
import { AdminGuard } from '@shared/guards/admin.guard';
import { IsAlreadyConnectedGuard } from '@shared/guards/is-already-connected.guard';
import { MustBeConnectedGuard } from '@shared/guards/must-be-connected.guard';
import { OpsEventNotificationService } from '@webservices/ops-event-api/ops-event-notification.service';
import { ToasterService } from '@widgets/toaster/toaster.service';
import {
  AUTHENTICATION_API_CONFIG,
  AuthenticationWebservice,
  CityWebservice,
  ContractWebservice,
  DegreeWebservice,
  EMAIL_API_CONFIG,
  EmailWebservice,
  FILE_API_CONFIG,
  FileWebservice,
  JOB_API_CONFIG,
  PLACE_API_CONFIG,
  PROFILE_API_CONFIG,
  ProfileWebservice,
  SCHOOL_API_CONFIG,
} from '@wizbii-utils/angular/webservices';
import { MustBeAuthorizedGuard } from '@wizbii/angular-backoffice-ui';

const routes: Routes = [
  {
    path: '',
    redirectTo: FeaturesRoutingEnum.Events,
    pathMatch: 'full',
  },
  {
    path: '',
    children: [
      {
        path: FeaturesRoutingEnum.SignIn,
        canActivate: [IsAlreadyConnectedGuard],
        loadChildren: () => import('./sign-in/sign-in.module').then((m) => m.SignInModule),
      },
      {
        path: '',
        canActivate: [MustBeConnectedGuard, MustBeAuthorizedGuard],
        component: LayoutComponent,
        children: [
          {
            path: FeaturesRoutingEnum.Administration,
            canActivate: [AdminGuard],
            loadChildren: () => import('./administration/administration.module').then((m) => m.AdministrationModule),
          },
          {
            path: FeaturesRoutingEnum.Events,
            children: [
              {
                path: '',
                redirectTo: `/${FeaturesRoutingEnum.Events}/${EventsRoutingEnum.List}`,
                pathMatch: 'full',
              },
              {
                path: `${EventsRoutingEnum.Detail}/${EventDetailRoutingEnum.parameters}`,
                loadChildren: () =>
                  import('./events/event-detail/parameters/parameters.module').then((m) => m.ParametersModule),
              },
              {
                path: EventsRoutingEnum.List,
                loadChildren: () => import('./events/events-list/events-list.module').then((m) => m.EventsListModule),
              },
              {
                path: EventsRoutingEnum.Detail,
                loadChildren: () =>
                  import('./events/event-detail/event-detail.module').then((m) => m.EventDetailModule),
              },
            ],
          },
          {
            path: FeaturesRoutingEnum.Companies,
            children: [
              {
                path: '',
                redirectTo: `/${FeaturesRoutingEnum.Companies}/${CompaniesRoutingEnum.List}`,
                pathMatch: 'full',
              },
              {
                path: CompaniesRoutingEnum.List,
                loadChildren: () =>
                  import('./companies/companies-list/companies-list.module').then((m) => m.CompaniesListModule),
              },
              {
                path: CompaniesRoutingEnum.Detail,
                loadChildren: () =>
                  import('./companies/company-detail/company-detail.module').then((m) => m.CompanyDetailModule),
              },
            ],
          },
          {
            path: FeaturesRoutingEnum.Candidates,
            children: [
              {
                path: '',
                redirectTo: `/${FeaturesRoutingEnum.Candidates}/${EventsRoutingEnum.List}`,
                pathMatch: 'full',
              },
              {
                path: CandidatesRoutingEnum.List,
                loadChildren: () =>
                  import('./candidates/candidate-list/candidate-list.module').then((m) => m.CandidateListModule),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '**',
    component: LayoutComponent,
    children: [
      {
        path: '**',
        component: ErrorComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [
    AdminGuard,
    MustBeConnectedGuard,
    IsAlreadyConnectedGuard,
    {
      provide: AUTHENTICATION_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.authentication,
      },
    },
    AuthenticationWebservice,
    {
      provide: PROFILE_API_CONFIG,
      useValue: {
        baseUrl: environment.api.profile,
      },
    },
    ProfileWebservice,
    {
      provide: PLACE_API_CONFIG,
      useValue: {
        baseUrl: environment.api.place,
      },
    },
    OpsEventNotificationService,
    CityWebservice,
    {
      provide: JOB_API_CONFIG,
      useValue: {
        baseUrl: environment.api.job,
      },
    },
    ContractWebservice,
    ToasterService,
    {
      provide: SCHOOL_API_CONFIG,
      useValue: {
        baseUrl: environment.api.school,
      },
    },
    DegreeWebservice,
    EmailWebservice,
    {
      provide: EMAIL_API_CONFIG,
      useValue: {
        baseUrl: environment.api.email,
      },
    },
    {
      provide: FILE_API_CONFIG,
      useValue: {
        baseUrl: environment.api.file,
        googleStorageUrl: environment.api.googleStorage,
        wizbiiFilesBucket: environment.wizbiiFiles,
      },
    },
    FileWebservice,
  ],
})
export class FeaturesModule {}
