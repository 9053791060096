export enum UserRoleEnum {
  ADMIN = 'ROLE_SUPER_ADMIN',
  SOURCING_MANAGER = 'ROLE_OPS_SOURCING_MANAGER',
  PROJECT_MANAGER = 'ROLE_OPS_PROJECT_MANAGER',
  USER = 'ROLE_SOURCING',
  VISITOR = 'ROLE_USER',
  EMPLOYEE_IT = 'ROLE_EMPLOYEE_IT',
  OPS_CRE = 'ROLE_OPS_CRE',
  TEAM_LEADER = 'ROLE_OPS_TEAM_LEADER',
}

export const UserRoleLabel: Partial<Record<UserRoleEnum, string>> = {
  [UserRoleEnum.ADMIN]: 'Administrateur',
  [UserRoleEnum.SOURCING_MANAGER]: 'Manager sourcing',
  [UserRoleEnum.PROJECT_MANAGER]: 'Chef de projet',
  [UserRoleEnum.USER]: 'Talent Acquisition Specialist',
  [UserRoleEnum.OPS_CRE]: 'CRE',
  [UserRoleEnum.TEAM_LEADER]: 'Team Leader',
};

export const adminRoles: string[] = [UserRoleEnum.ADMIN];

export const managerRoles: string[] = [
  UserRoleEnum.PROJECT_MANAGER,
  UserRoleEnum.SOURCING_MANAGER,
  UserRoleEnum.OPS_CRE,
];

export const userRoles: string[] = [UserRoleEnum.USER, UserRoleEnum.TEAM_LEADER];

export const sourcingRoles: string[] = [...adminRoles, ...managerRoles, ...userRoles];
