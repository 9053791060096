import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngxs/store';
import { UpdateUserStatus } from '@store/voip/voip.action';
import { SwitchComponent } from '@widgets/switch/switch.component';

@Component({
  standalone: true,
  selector: 'app-tel-on-off',
  templateUrl: './tel-on-off.component.html',
  styleUrls: ['./tel-on-off.component.scss'],
  imports: [CommonModule, SwitchComponent, MatIconModule, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelOnOffComponent {
  readonly #store = inject(Store);

  @Input() isOnline: boolean;

  updateUserStatus(status: boolean): void {
    this.#store.dispatch(new UpdateUserStatus(status));
  }
}
