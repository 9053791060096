import { Criteria } from '@shared/models/criteria';

export class CvBookParameters {
  clientName: string;
  imageFileId: string;
  recipients: string[];
  interviewDuration: number;
  interviewDebriefingDuration: number;
  interviewBreakDuration: number;
  criteriaGroups: Criteria[];
  committeeTypes: string[];

  constructor(data: Record<string, any>) {
    this.clientName = data['clientName'];
    this.imageFileId = data['imageFileId'];
    this.recipients = data['recipients'];
    this.interviewDuration = data['interviewDuration'];
    this.interviewDebriefingDuration = data['interviewDebriefingDuration'];
    this.interviewBreakDuration = data['interviewBreakDuration'];
    this.criteriaGroups = data['criteriaGroups'];
    this.committeeTypes = data['committeeTypes'];
  }
}
