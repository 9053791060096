import { Company } from '@shared/models/company';
import { OpsJob } from '@shared/models/ops-job';
import { OpsCommittee } from '@wizbii/utils/models';

export class CompanyListCopyJobToClipboard {
  static readonly type = 'CompanyListCopyJobToClipboard';
  constructor(public job: OpsJob) {}
}

export class GetCompanyList {
  static readonly type = '[OpsEventApi] GetCompanyList';
  constructor(public eventId: string) {}
}

export class AddCompanyToEvent {
  static readonly type = '[OpsEventApi] AddCompanyToEvent';
  constructor(
    public eventId: string,
    public companyId: string
  ) {}
}

export class MoveJobs {
  static readonly type = '[OpsEventApi] MoveJobs';
  constructor(
    public eventId: string,
    public companies: Company[]
  ) {}
}

export class AddRecruiterToEvent {
  static readonly type = '[OpsEventApi] AddRecruiterToEvent';
  constructor(
    public companyId: string,
    public recruiterId: string,
    public eventId: string
  ) {}
}

export class DeleteRecruiterToEvent {
  static readonly type = '[OpsEventApi] DeleteRecruiterToEvent';
  constructor(
    public companyId: string,
    public recruiterId: string,
    public eventId: string
  ) {}
}

export class AddJobToCompany {
  static readonly type = '[OpsEventApi] AddAddJobToCompany';
  constructor(
    public job: OpsJob,
    public companyId: string,
    public eventId: string
  ) {}
}

export class EditJob {
  static readonly type = '[OpsEventApi] EditJob';
  constructor(
    public job: OpsJob,
    public companyId: string,
    public eventId: string
  ) {}
}

export class DeleteJob {
  static readonly type = '[OpsEventApi] DeleteJob';
  constructor(
    public jobId: string,
    public companyId: string,
    public eventId: string
  ) {}
}

export class ApproveJob {
  static readonly type = '[OpsEventApi] ApproveJob';
  constructor(
    public jobId: string,
    public companyId: string,
    public eventId: string
  ) {}
}

export class RejectJob {
  static readonly type = '[OpsEventApi] RejectJob';
  constructor(
    public jobId: string,
    public companyId: string,
    public eventId: string
  ) {}
}

export class EditCommittee {
  static readonly type = '[OpsEventApi] EditCommittee';
  constructor(
    public committees: OpsCommittee[],
    public companyId: string,
    public eventId: string
  ) {}
}

export class GenerateEventPlanning {
  static readonly type = '[OpsEventApi] Generate Company Planning';
  constructor(public eventId: string) {}
}

export class DeleteEventPlanning {
  static readonly type = '[OpsEventApi] Delete Company Planning';
  constructor(public eventId: string) {}
}
