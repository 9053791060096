import { NotificationResponse, NotificationResponseSubject } from '@shared/models/notifications/notification-response';

export class GetNotifications {
  static readonly type = '[Notifications] Get notifications';
}

export class AddNotification<T extends NotificationResponseSubject> {
  static readonly type = '[Notifications] Add a notification';
  constructor(public notification: NotificationResponse<T>) {}
}

export class HandleNotification<T extends NotificationResponseSubject> {
  static readonly type = '[Notifications] handle a notification';
  constructor(public notification: NotificationResponse<T>) {}
}

export class MarkAsRead<T extends NotificationResponseSubject> {
  static readonly type = '[Notifications] Mark notification as read';
  constructor(public notification: NotificationResponse<T>) {}
}
