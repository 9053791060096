import { Injectable } from '@angular/core';
import { FeedbackToast } from '@widgets/toaster/toaster';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ToasterService {
  protected _toasts$ = new Subject<FeedbackToast>();

  show(toast: FeedbackToast): void {
    if (toast) {
      this._toasts$.next(toast);
    }
  }

  get toasts$(): Observable<FeedbackToast> {
    return this._toasts$.asObservable();
  }
}
