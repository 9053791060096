import { MailAttachment } from '@shared/models/mail-attachment';

export class Mail {
  id: string;
  headers: Record<string, any>;
  dkim: Record<string, any>;
  spf: string;
  rawCc: string;
  from: string;
  text: string;
  html: string;
  senderIp: string;
  spamReport: string;
  spamScore: string;
  envelope: Record<string, any>;
  subject: string;
  charsets: Record<string, any>;
  attachments: MailAttachment[];
  attachmentInfo: Record<string, any>;
  dateCreated: Date;

  constructor(data: Record<string, any>) {
    this.id = data['id'];
    this.headers = data['headers'];
    this.dkim = data['dkim'];
    this.spf = data['spf'];
    this.rawCc = data['rawCc'];
    this.from = data['from'];
    this.text = data['text'];
    this.html = data['html'];
    this.senderIp = data['senderIp'];
    this.spamReport = data['spamReport'];
    this.spamScore = data['spamScore'];
    this.envelope = data['envelope'];
    this.subject = data['subject'];
    this.charsets = data['charsets'];
    this.attachments = (data['attachments'] || []).map((item: any) => new MailAttachment(item));
    this.attachmentInfo = data['attachmentInfo'];
    this.dateCreated = new Date(data['dateCreated']);
  }
}
